const SET_SELECTED_STATE = 0x0001;

const SET_STATE_ONLY_VIEW = 0x0002;

const SET_DISTRICT = 0x0003;

// const GET_FORM_QUESTIONS = 0x0004;

const SET_FORM_DATA = 0x0005;

/**
 *
 * @constant {number} Captures gps coordinates
 */
const CAPTURE_GPS = 0x0006;

/**
 *
 * @constant {number} Scroll to  question from  element clicked from
 */
const FOCUS_QUESTION = 0x0007;

/**
 *
 * @constant {number} Set users data in local storage as well as  UserProvider Context
 */
const SET_USER = 0x0008;
/**
 *
 * @constant {number} Toggle Entire app theme
 */
const TOGGLE_THEME = 0x0009;
/**
 *
 * @constant {number} Toggle current form view i.e Horizontal or vertical
 */
const TOGGLE_FORM_VIEW = 0x0010;

const SET_LAST_UPDATED_QUESTION_INDEX = 0x0011;

const LOGOUT_USER = 0x0012;

const SET_UNIT_VALUE = "setUnitVal";

const SET_VILLAGE_WISE_LIMITS = "svwl";

export {
  SET_SELECTED_STATE,
  SET_USER,
  SET_STATE_ONLY_VIEW,
  SET_DISTRICT,
  SET_FORM_DATA,
  CAPTURE_GPS,
  FOCUS_QUESTION,
  TOGGLE_THEME,
  TOGGLE_FORM_VIEW,
  SET_LAST_UPDATED_QUESTION_INDEX,
  SET_VILLAGE_WISE_LIMITS,
  LOGOUT_USER,
  SET_UNIT_VALUE,
};
