const BASE_URL = process.env.REACT_APP_BASEURL;

const getApiWithLanguageParams = (url) => {
    let apiUrl = new URL(makeApiURl(url));
    const appLanguage = localStorage.getItem('appLanguage');
    if (appLanguage) {
        apiUrl.searchParams.set('lng', appLanguage);
    } else {
        apiUrl.searchParams.set('lng', '1');
    }
    return apiUrl.href;
};

const makeApiURl = (url) => `${BASE_URL}${url}`;

export { makeApiURl, BASE_URL, getApiWithLanguageParams };
