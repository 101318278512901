import React from 'react';
import {userReducer} from "../reducers";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function setInitialState() {
    if (localStorage.getItem("user") && localStorage.getItem("token")) {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        return {
            ...user, token: localStorage.getItem('token')
        }
    }
    return {};
}

export default function UserProvider({children}) {
    const [state, dispatch] = React.useReducer(userReducer, setInitialState());
    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>);

}


export {UserStateContext, UserDispatchContext}
