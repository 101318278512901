import "./index.scss";

import React, { useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Pdf from "../../assets/ALEKH_Newsletter_August_2022.pdf";
import { NAVBAR } from "../../assets/strings/en";
import { LanguageTranslationProvider } from "../../contexts/language.context";
import { UserStateContext } from "../../contexts/user-context";

export function Topnav() {
  const translate = React.useContext(LanguageTranslationProvider);
  const user = useContext(UserStateContext);
  return (
    <Navbar bg="secondary90" expand="lg" variant="dark">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey="/home">
            <NavLink className="nav-link" to={"home"}>
              <img
                className="img-fluid"
                src={require("../../assets/icons/home.svg")}
              />
            </NavLink>
            <NavLink className="nav-link" to={"dashboard"}>
              {translate(NAVBAR.DASHBOARD)}
            </NavLink>
            <NavLink className="nav-link" to={"knowledge-repository"}>
              {translate(NAVBAR.KNOWLEDGE_REPOSITORY)}
            </NavLink>
            <NavLink className="nav-link" to={"partnerOrganisation"}>
              {translate(NAVBAR.PO)}
            </NavLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link "
              href={"https://scdcorner.in"}
            >
              {translate(NAVBAR.SCD)}
            </a>
            <a className="nav-link" href={Pdf} target="_blank">
              {translate(NAVBAR.ALEKHA)}{" "}
            </a>
            <NavLink className="nav-link" to={"tribal"}>
              {translate(NAVBAR.AASHWAN)}
            </NavLink>
            <NavLink className="nav-link disabled" to={"tribal-health-plan"}>
              {translate(NAVBAR.TRIBAL_HEALTH_PLAN)}
            </NavLink>
            <NavLink className="nav-link disabled" to={"budget-tracker"}>
              {translate(NAVBAR.BUDGET_TRACKER)}
            </NavLink>
            {"token" in user && (
              <NavDropdown title="Configuration" id="basic-nav-dropdown">
                <NavLink
                  className="dropdown-item"
                  to="partnerOrganisation/list"
                >
                  Partner
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="knowledge-repository/items"
                >
                  Resources
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="knowledge-repository/tribe"
                >
                  Tribes
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="knowledge-repository/resource"
                >
                  Resource Types
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="knowledge-repository/keyword"
                >
                  Keyword
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="knowledge-repository/newsFeed"
                >
                  News Feed
                </NavLink>
                <NavLink className="dropdown-item" to="tribal/create">
                  Aashwasan Coverage
                </NavLink>
                <NavLink className="dropdown-item" to="login-history/list">
                  Login History
                </NavLink>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
