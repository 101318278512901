import React from "react";
import AashwasanBulkUpload from "./AashwasanBulkUpload";
import AashwasanForm from "./aashwasanForm";

const CreateAashwasan = () => {
  return (
    <div className="row ">
      <AashwasanForm />
      <AashwasanBulkUpload />
    </div>
  );
};

export default CreateAashwasan;
