export const languages = [
    {
        lng: `1`,
        code: 'en',
        name: 'English',
    },
    {
        lng: `2`,
        code: 'hi',
        name: 'Hindi',
    }
]
