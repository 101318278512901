import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useFetch } from "../../hooks";
import { getApiWithLanguageParams } from "../../services";
import { converDateToDayMonthYear, convertIndicatorsName } from "../../utils/helpers";
import DashboardCardForDistrict from "../dashboard-card/DashboardCardForDistrict";

const excludedKeys = [
  "id",
  "name_key",
  "name",
  "code",
  "total_blocks_covered",
  "total_villages_covered",
  "total_people_reached",
];

const DistrictMapDetails = () => {
  const [districtLoading, districtRes] = useFetch(
    getApiWithLanguageParams("district-details?active=true")
  );

  const [indicatorLoading, indicators] = useFetch(
    getApiWithLanguageParams(`aashwasanMapIndicators`)
  );

  const [visibleIndicators, setVisibleIndicators] = useState(null);

  const getTotalFromArrOfObjects = (arr) => {
    const obj = arr.reduce((acc, current) => ({
      total_blocks_covered:
        +acc.total_blocks_covered + +current.total_blocks_covered,
      total_districts_covered:
        +acc.total_districts_covered + +current.total_districts_covered,
      total_people_reached:
        +acc.total_people_reached + +current.total_people_reached,
      total_people_screened_for_tb:
        +acc.total_people_screened_for_tb +
        +current.total_people_screened_for_tb,
      total_people_with_persumptive_tb:
        +acc.total_people_with_persumptive_tb +
        +current.total_people_with_persumptive_tb,
      total_people_tested_for_tb:
        +acc.total_people_tested_for_tb + +current.total_people_tested_for_tb,
      total_positive_tb_cases:
        +acc.total_positive_tb_cases + +current.total_positive_tb_cases,
      total_positive_persons_started_on_treatment:
        +acc.total_positive_persons_started_on_treatment +
        +current.total_positive_persons_started_on_treatment,
    }));
    return obj;
  };

  useEffect(() => {
    if (indicators.length) {
      console.log("indicators: ", getTotalFromArrOfObjects(indicators));
      setVisibleIndicators(getTotalFromArrOfObjects(indicators));
    }
  }, [indicators]);

  const tempIcon =
    "https://stgmotaapi.dhwaniris.in/objects/ef50e2c7-9414-4057-b261-4783a8360fb4.svg";
  return (
    <div>
      <h3 className="mb-4">Aashwasan: Coverage</h3>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {visibleIndicators ? (
          Object.keys(visibleIndicators).map((k) => {
            if (!excludedKeys.includes(k)) {
              return (
                <DashboardCardForDistrict
                  key={k}
                  imageUrl={tempIcon}
                  title={convertIndicatorsName(k)}
                  description={visibleIndicators[k].toLocaleString()}
                />
              );
            }
          })
        ) : (
          <p>No Data</p>
        )}
      </div>
      <Card className="shadow-sm mb-3">
        <Card.Body className="p-3">
          As on:{" "}
          {converDateToDayMonthYear(districtRes[0]?.as_on_date)}
        </Card.Body>
      </Card>
    </div>
  );
};

export default DistrictMapDetails;
