import React from "react";
import {Route, useNavigate} from 'react-router-dom';
import {UserStateContext} from "../../contexts/user-context";


export function RestrictedRoute({children, ...rest}) {

    const user = React.useContext(UserStateContext);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!('token' in user)) navigate('/home');
    })
    return ('token' in user) ? <Route {...rest}>{children}</Route> : null;

}
