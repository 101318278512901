import {SET_USER, LOGOUT_USER} from "./constants";

function userReducer(state, action) {
    switch (action.type) {
        case LOGOUT_USER:
            localStorage.clear();
            return {};
        case SET_USER:
            return {
                ...state,
                ...action.payload
            }
    }
    return {...state};
}

export default userReducer
