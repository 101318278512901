import React from "react";
import {keys} from "../assets/strings";


function getDefaultLanguage() {
    const storedLanguage = localStorage.getItem('appLanguage');
    if (storedLanguage)
        return storedLanguage;
    return `1`;
}

export function t(key, languageId) {
    if (!languageId) return 'No language Selected';
    switch (languageId) {
        case `2`:
            return keys.hi[key] || `Translation not found for  ${keys.en[key]}`;
        default:
            return keys.en[key] || `Translation not found for  ${keys.en[key]}`
    }
}

export const LanguageDataContext = React.createContext();
export const LanguageDispatchContext = React.createContext(null);

const languageReducer = (state, action) => {
    localStorage.setItem('appLanguage', action.type);
    switch (action.type) {
        case '1': {
            return {lng: '1'}
        }
        case '2': {
            return {lng: '2'}
        }
        default: {
            return {lng: '1'}
        }
    }
}


/**
 * Global language state provider
 * @param children
 * @returns {*}
 * @constructor
 */

export const LanguageTranslationProvider = React.createContext();

export default function LanguageProvider({children}) {

    const [state, dispatch] = React.useReducer(languageReducer, {lng: getDefaultLanguage()})

    return (
        <LanguageDataContext.Provider value={state}>
            <LanguageDispatchContext.Provider value={dispatch}>
                <LanguageTranslationProvider.Provider value={(key) => t(key, state.lng)}>
                    {children}
                </LanguageTranslationProvider.Provider>
            </LanguageDispatchContext.Provider>
        </LanguageDataContext.Provider>
    )
}


