import React, { useEffect, useRef, useState } from "react";
import stateBoundaries from "./state_boundries.json";
import districtBoundaries from "../../assets/json/states_with_district_boundaries.json";
import * as L from "leaflet";
import { renderToString } from "react-dom/server";
import { useFetch } from "../../hooks";
import { getApiWithLanguageParams } from "../../services";
import { getHeatmapColor } from "../../utils";
import "./index.scss";
import { Card } from "react-bootstrap";
import { converDateToDayMonthYear } from "../../utils/helpers";

const layerStyle = {
  color: "#808080",
  fill: true,
  fillColor: "#0ff",
  weight: 0.6,
  opacity: 1,
  fillOpacity: 1,
};

const legendStyle = {
  borderWidth: "1px",
  // borderStyle: 'solid',
  width: "130px",
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  bottom: 0,
  fontSize: "0.8rem",
  padding: "0.5rem",
};

const LegendElement = (
  <Card style={legendStyle}>
    <div className="flex-1 card-title">
      <b>TB Test Positivity</b>
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#ffff00",
        }}
      />
      Yet to begin
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#C0C0C0",
        }}
      />
      &gt; 0% - 2%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#87CEFA",
        }}
      />
      2% - 4%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#00BFFF",
        }}
      />
      4% - 6%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#0000CD",
        }}
      />
      6% - 8%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#00008B",
        }}
      />
      8% - 10%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#ff0000",
        }}
      />
      &gt; 10%
    </div>
  </Card>
);

const TBPositiveRateMap = () => {
  const mapContainer = useRef();
  const currentLayer = useRef();
  const boundaries = useRef();

  const [indicatorsLoading, indicators] = useFetch(
    getApiWithLanguageParams(`aashwasanMapIndicators`)
  );

  useEffect(() => {
    if (document.getElementById("mapid3")) {
      if (!mapContainer.current) {
        mapContainer.current = L.map("mapid3", {
          preferCanvas: false,
          zoomControl: false,
          doubleClickZoom: false,
          touchZoom: false,
          scrollWheelZoom: false,
          zoom: false,
          dragging: false,
          minZoom: 4,
          zoomSnap: 0.25,
          zoomDelta: 0.25,
        });
        const legend = L.control({ position: "bottomleft" });
        legend.onAdd = function (map) {
          const div = L.DomUtil.create("div");
          div.innerHTML = renderToString(LegendElement);
          return div;
        };
        legend.addTo(mapContainer.current);
      } else {
        mapContainer.current.removeLayer(currentLayer.current);
        if (mapContainer.current) {
          setTimeout(() => {
            mapContainer.current.invalidateSize();
          }, 300);
        }
      }

      currentLayer.current = L.geoJSON(districtBoundaries, {
        style: (feature) => {
          let foundIndicator = indicators.find(
            (ind) =>
              ind.district_name === feature.properties.DISTRICT &&
              ind.district_name !== "Balrampur"
          );

          return {
            ...layerStyle,
            fillColor: foundIndicator
              ? getHeatmapColor(
                  (
                    (+foundIndicator.total_positive_tb_cases /
                      +foundIndicator.total_people_tested_for_tb) *
                    100
                  ).toFixed(2)
                )
              : "#fff",
          };
        },
        onEachFeature: (feature, layer) => {
          let foundIndicator = indicators.find(
            (ind) =>
              ind.district_name === feature.properties.DISTRICT &&
              ind.district_name !== "Balrampur"
          );

          if (foundIndicator) {
            layer.bindTooltip(
              renderToString(
                <>
                  <h4>{feature.properties.DISTRICT}</h4>
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td>TB positivity rate %</td>
                        <td>
                          {isNaN(
                            +foundIndicator.total_positive_tb_cases /
                              +foundIndicator.total_people_tested_for_tb
                          ) ||
                          +foundIndicator.total_positive_tb_cases /
                            +foundIndicator.total_people_tested_for_tb ===
                            0
                            ? "Yet to begin"
                            : (
                                (+foundIndicator.total_positive_tb_cases /
                                  +foundIndicator.total_people_tested_for_tb) *
                                100
                              ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )
            );
          } else {
            L.Util.setOptions(layer, {
              interactive: false,
            });
            layer.setStyle({
              className: "nonTribal",
            });
          }
        },
      }).addTo(mapContainer.current);

      mapContainer.current.fitBounds(currentLayer.current.getBounds());
      if (mapContainer.current) {
        setTimeout(() => {
          mapContainer.current.invalidateSize();
        }, 300);
      }
    }
  }, [indicators]);

  return (
    <div>
      <h4 style={{ textAlign: "center" }}>
        TB Test Positivity Rate (%) <br />{" "}
        <small>
          As on:{" "}
          {converDateToDayMonthYear(indicators[0]?.as_on_date || null)}
        </small>
      </h4>
      <div key={"leaflet-map"} id="mapid3" />
    </div>
  );
};

export default TBPositiveRateMap;
