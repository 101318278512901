import "./index.scss";

import * as L from "leaflet";
import React from "react";
import { Card } from "react-bootstrap";
import { renderToString } from "react-dom/server";
import { SVGMap } from "react-svg-map";

import data from "../../assets/data";
import boundaries from "../../assets/json/states_with_district_boundaries.json";
import {
  MapDataContext,
  MapDispatchContext,
} from "../../contexts/dashboard-map";
import { SET_DISTRICT, SET_STATE_ONLY_VIEW } from "../../reducers/constants";
import { getColor, getInIndianFormat, getPosition } from "../../utils";
import { Tooltip } from "../tooltip";

const legendStyle = {
  borderWidth: "1px",
  // borderStyle: 'solid',
  width: "130px",
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  bottom: 0,
  fontSize: "0.8rem",
  padding: "0.5rem",
};

const LegendElement = (
  <Card style={legendStyle}>
    <div className="flex-1 card-title">
      <b>Tribal Population</b>
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#f0ff00",
        }}
      />
      0% - 20%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#ffe700",
        }}
      />
      20% - 40%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#ffc100",
        }}
      />
      40% - 60%
    </div>
    <div className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          marginRight: ".4rem",
          background: "#ffb400",
        }}
      />
      60% - 100%
    </div>
  </Card>
);

const layerStyle = {
  color: "#808080",
  fill: true,
  fillColor: "#fff",
  weight: 0.6,
  opacity: 1,
  fillOpacity: 1,
};

function LeafletMap({ states = [], districts = [] }) {
  // console.log("Data: ", states);
  const { selectedState, stateOnly, districtOnly, selectedDistrict, geoType } =
    React.useContext(MapDataContext);
  const dispatch = React.useContext(MapDispatchContext);
  const mapContainer = React.useRef();
  const currentLayer = React.useRef();
  const [currentPathId, setPath] = React.useState(null);
  const [hoveredState, setHoverState] = React.useState(null);

  const tooltipElement = (
    <>
      <h5 className="text-center text-secondary">
        {hoveredState && hoveredState.name}
      </h5>
      <table className="table table-sm mb-0">
        <tbody>
          <tr>
            <td>Total Districts</td>
            <td>
              {(hoveredState && hoveredState.indicator_data.total_districts) ||
                ""}
            </td>
          </tr>
          <tr>
            <td>Tribal District</td>
            <td>
              {(hoveredState &&
                hoveredState.indicator_data["Tribal Districts"] &&
                hoveredState &&
                hoveredState.indicator_data["Tribal Districts"].toLocaleString(
                  "en-in"
                )) ||
                ""}
            </td>
          </tr>
          <tr>
            <td>Total population</td>
            <td>
              {(hoveredState &&
                hoveredState.indicator_data.total_population &&
                hoveredState.indicator_data.total_population.toLocaleString(
                  "en-in"
                )) ||
                ""}
            </td>
          </tr>
          <tr>
            <td>Tribal population</td>
            <td>
              {(hoveredState &&
                hoveredState.indicator_data.tribal_population &&
                hoveredState &&
                hoveredState.indicator_data.tribal_population.toLocaleString(
                  "en-in"
                )) ||
                ""}
            </td>
          </tr>
          <tr>
            <td>% Tribal Population</td>
            <td>
              {(hoveredState &&
                hoveredState.indicator_data.tribal_population_percentage &&
                hoveredState.indicator_data.tribal_population_percentage.toLocaleString(
                  "en-in"
                )) ||
                ""}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  React.useEffect(() => {
    if (geoType === "india") {
      mapContainer.current = null;
    }
  }, [geoType, selectedState, stateOnly]);

  React.useEffect(() => {
    if (document.getElementById("mapid")) {
      if (!mapContainer.current) {
        mapContainer.current = L.map("mapid", {
          preferCanvas: false,
          zoomControl: false,
          doubleClickZoom: false,
          touchZoom: false,
          scrollWheelZoom: false,
          zoom: false,
          dragging: false,
          style: {
            background: "red",
            marginTop: "2rem",
          },
        }).setView([20, 78]);
        const legend = L.control({ position: "bottomleft" });
        legend.onAdd = function (map) {
          const div = L.DomUtil.create("div");
          div.innerHTML = renderToString(LegendElement);
          return div;
        };
        legend.addTo(mapContainer.current);
      } else {
        mapContainer.current.removeLayer(currentLayer.current);
        if (mapContainer.current) {
          setTimeout(() => {
            mapContainer.current.invalidateSize();
          }, 300);
        }
      } 
      const stateBoundaries = boundaries.features.filter(
        (state) =>
          Number(state.properties.ST_CEN_CD) == Number(selectedState.state_code)
      );
      if (stateBoundaries.length) {
        currentLayer.current = L.geoJSON(
          {
            ...boundaries,
            features: stateBoundaries,
          },
          {
            style: (feature) => {
              const district = districts.find(
                (d) =>
                  +d.code === +feature.properties.DT_CEN_CD ||
                  d.name == feature.properties.DISTRICT ||
                  (d.code == "1015"
                    ? feature.properties.DT_CEN_CD == "1020" ||
                      feature.properties.DT_CEN_CD == "299"
                    : false)
              );

              return {
                ...layerStyle,
                color: selectedDistrict.find(
                  (d) =>
                    +d.code === +feature.properties.DT_CEN_CD ||
                    d.name == feature.properties.DISTRICT ||
                    (d.code == "1015"
                      ? feature.properties.DT_CEN_CD == "1020" ||
                        feature.properties.DT_CEN_CD == "299"
                      : false)
                )
                  ? "#004B4E"
                  : "#585757",
                weight: selectedDistrict.find(
                  (d) =>
                    +d.code === +feature.properties.DT_CEN_CD ||
                    d.name == feature.properties.DISTRICT ||
                    (d.code == "1015"
                      ? feature.properties.DT_CEN_CD == "1020" ||
                        feature.properties.DT_CEN_CD == "299"
                      : false)
                )
                  ? "4"
                  : 1,
                fillColor: district
                  ? getColor(
                      district.indicator_data.tribal_population_percentage
                    )
                  : getColor(0),
              };
            },
            onEachFeature: (feature, layer) => {
              layer.on({
                click: (layer, b) => {
                  let selectedDistrict = districts.find(
                    (d) =>
                      +d.code === +layer.target.feature.properties.DT_CEN_CD ||
                      d.name == layer.target.feature.properties.DISTRICT ||
                      (d.code == "1015"
                        ? feature.properties.DT_CEN_CD == "1020" ||
                          feature.properties.DT_CEN_CD == "299"
                        : false)
                  );

                  dispatch({
                    type: SET_DISTRICT, 
                    payload: [selectedDistrict],
                  });
                },
              });

              const selectedDistrict = districts.find(
                (d) =>
                  +d.code === +feature.properties.DT_CEN_CD ||
                  d.name == feature.properties.DISTRICT ||
                  (d.code == "1015"
                    ? feature.properties.DT_CEN_CD == "1020" ||
                      feature.properties.DT_CEN_CD == "299"
                    : false)
              );

              if (selectedDistrict) {
                layer.bindTooltip(
                  renderToString(
                    <>
                      <h4 className="text-center text-secondary">
                        {selectedDistrict.name}
                      </h4>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td>Tribal population</td>
                            <td>
                              {selectedDistrict &&
                                getInIndianFormat(
                                  selectedDistrict.indicator_data
                                    .tribal_population
                                )}
                            </td>
                            <tr>
                              <td>Total population</td>
                              <td>
                                {selectedDistrict &&
                                  getInIndianFormat(
                                    selectedDistrict.indicator_data
                                      .total_population
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>% Tribal Population</td>
                              <td>
                                {selectedDistrict &&
                                  getInIndianFormat(
                                    selectedDistrict.indicator_data
                                      .tribal_population_percentage
                                  )}
                              </td>
                            </tr>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )
                );
              } else {
                L.Util.setOptions(layer, {
                  interactive: false,
                });
                layer.setStyle({
                  className: "nonTribal",
                });
              }
            },
          }
        ).addTo(mapContainer.current);
        mapContainer.current.fitBounds(currentLayer.current.getBounds());
        if (mapContainer.current) {
          setTimeout(() => {
            mapContainer.current.invalidateSize();
          }, 300);
        }
      }
    }
  }, [dispatch, districts, selectedDistrict, selectedState, stateOnly]);

  React.useEffect(() => {
    if (mapContainer.current) {
      setTimeout(() => {
        mapContainer.current.invalidateSize();
      }, 300);
    }
  }, [districtOnly]);

  React.useEffect(() => {
    let path = "";
    if (hoveredState) {
      path = data.locations.find(
        (location) =>
          location.id.toUpperCase() === hoveredState.code.toUpperCase()
      );
    }
    if (selectedState) {
      path = data.locations.find(
        (location) =>
          location.id.toUpperCase() === selectedState.code.toUpperCase()
      );
    }
    if (path) {
      setPath(path.id);
    } else {
      setPath(null);
    }
  }, [selectedState, hoveredState]);

  React.useEffect(() => {
    data.locations.forEach((path) => {
      let element = document.getElementById(path.id);
      if (element) {
        const selectedState = states.find(
          (state) => state.code === path.id.toUpperCase()
        );
        element.classList.remove("selected");
      }
    });
    const pathElement = document.getElementById(currentPathId);
    if (pathElement) {
      pathElement.classList.add("selected");
    }
  }, [currentPathId, stateOnly, selectedState, states]);

  React.useEffect(() => {
    states.forEach(
      ({ code, indicator_data: { total_population, tribal_population } }) => {
        let element = document.getElementById(code);
        if (element) {
          element.classList.add("allSates");
          if (total_population && tribal_population) {
            element.style.fill = getColor(
              (tribal_population / total_population) * 100
            );
          } else {
            element.classList.add("allSates");
          }
        }
      }
    );
  }, [states, stateOnly]);

  React.useEffect(() => {
    if (geoType === "district") {
      if (districts.length && selectedDistrict.length < 1) {
        dispatch({ type: SET_DISTRICT, payload: [districts[0]] });
      }
    }
  }, [dispatch, districts, geoType, selectedDistrict.length]);

  let containerRef = React.useRef(null);
  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("mouseleave", (e) => {
        setHoverState(null);
      });
    }
  }, []);

  function onHover(event) {
    const { id } = event.target;
    const selectedState = states.find(
      (state) => state.code === id.toUpperCase()
    );
    setHoverState(selectedState);
  }

  function onLocationClicked(element) {
    const { id } = element.target;
    const selectedState = states.find(
      (state) => state.code === id.toUpperCase()
    );
    // if (selectedState.code === 'LDK') return;
    dispatch({ type: SET_STATE_ONLY_VIEW, payload: selectedState });
  }
  if (geoType === "india") {
    return (
      <div key={"svg-map"} ref={containerRef} id="svg-map">
        <Tooltip
          children={tooltipElement}
          position={getPosition(document.getElementById(currentPathId))}
        />
        <SVGMap
          onLocationMouseOver={onHover}
          onLocationClick={onLocationClicked}
          locationClassName={"nonTribal"}
          role="d"
          id="svg-map"
          map={data}
        />
        {LegendElement}
      </div>
    );
  }

  if (geoType === "state" || geoType === "district") {
    return <div key={"leaflet-map"} id="mapid" />;
  }

  return null;
}

export { LeafletMap };
