export const NAVBAR = {
  LOGIN: "Login",
  LOGOUT: "Logout",
  HOME: "Home",
  DASHBOARD: "Dashboard",
  PO: "Partners",
  KNOWLEDGE_REPOSITORY: "Knowledge Repository",
  TRIBAL_HEALTH_PLAN: "Tribal Health Plan",
  BUDGET_TRACKER: "Budget Tracker",
  ABOUT: "About Us",
  RELATED_WEBSITE: "Related Websites",
  CONTACT_US: "Contact Us",
  ALEKHA: "ALEKH",
  AASHWAN: "Tribal TB Initiative",
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  YOUTUBE: "Youtube",
  IG: "Instagram",
  SCD: "SCD Support Corner",
  PLEASE_WAIT: "Please wait...",
};

export const LOGIN = {
  PLEASE_WAIT: "Please wait...",
  ERROR: "Some error occurred. Please try Again.",
};

export const LOGOUT = {
  PLEASE_WAIT: "Please wait...",
  ERROR: "Some error occurred. Please try Again.",
};

export const FOOTER = {
  MANAGED_BY:
    "©2019-2020  Managed by Piramal Swasthya  Management and Research Institute. All Right Reserved.",
  FOLLOW_US: "Follow Us",
  DISCLAIMER:
    "<p><i>The Swasthya knowledge portal is an attempt of the Centre of Excellence under the aegis of the Ministry of Tribal Affairs. The objective is to provide collated information and ease visualisation to ensure data driven decision making in the area of tribal health and nutrition. For further analysis, research and reference purposes, users are recommended to refer to the indicated primary sources.</p><p>Map is currently displayed as per Census, 2011 data. This will be updated for the latest districts as per Local Government Directory shortly.</i></p>",
};

export const HOMEPAGE = {
  SLIDE_TEXT1:
    "As per 1951 census 5.6% of the total population of the country was tribal.",
  SLIDE_TEXT2:
    "As per 1951 census 5.6% of the total population of the country was tribal.",
  SLIDE_TEXT3:
    "As per 1951 census 5.6% of the total population of the country was tribal.",
  ABOUT_US: "About Us",
  ABOUT_US_TEXT_P1:
    "Swasthya is a one-stop solution presenting the health and nutrition status of the tribal population of India. It provides information and data as well as curates innovative practices, research briefs, case studies and best practices collected from different parts of India to facilitate the exchange of evidence, expertise and experiences.",
  ABOUT_US_TEXT_P2:
    "It is envisaged that information available on Swasthya will provide insights to all stakeholders working with the tribal population of India, in the areas of health and nutrition. Further, it is hoped that this will result in evidence-based policy making.",
  ABOUT_US_TEXT_P3:
    "Swasthya is maintained by the Centre of Excellence for Knowledge Management for Health and Nutrition, established by the Ministry of Tribal Affairs.",
  READ_MORE: "Read more...",
  OUR_PARTNER: "Our Partners",
  WHATS_NEW: "What's New",
  GLIMPSES_FROM_FIELD: "Glimpses From Field",
  WHATS_NEW_HEADING: "News heading lorem ipsum dolor sit",
  WHATS_NEW_DESC:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  WHATS_NEW_DATE: "Posted",
  SEE_ALL: "See All",
  PREV: "Previous",
  NEXT: "Next",
  SOCIAL_MEDIA_POST: "Socials Media Posts",
};

export const ABOUTPAGE = {
  Y_1951: "1951",
  Y_2011: "2011",
  CENSUS: "Census",
  Y_1951_TEXT: "5.6% of the total population of he country was tribal.",
  Y_2011_TEXT:
    "8.6% of the total population of he country was tribal, which is equal to 10,43,81,034 in numbers.",
  ABOUT_TEXT: "About",
  ABOUT_TITLE: "Tribal Health in India",
  ABOUT_YEARS: "In service for over 19+ Years",
  ABOUT_TEXT_P1:
    "Comprising 104 million individuals, India’s tribal population predominantly lives in hilly, forested areas that are difficult to access. There is a vast difference between the health outcomes of India’s tribal population vis-à-vis its non-tribal population. Maternal mortality, under-five mortality and malnutrition rates are higher among tribal groups. In addition, there is a higher prevalence of malaria, tuberculosis and other communicable diseases.",
  ABOUT_TEXT_P2:
    "The current status of tribal health and nutrition requires all stakeholders to come together and join forces to improve the overall scenario. However, the biggest bottleneck in this area, often hampering policy-making, is the absence of data specific to the tribal communities.",
  OUR_WORK: "Our Work",
  WORK_TOGETHER: "Knowledge Management for Tribal Health and Nutrition",
  ABOUT_S2_TEXT_P1:
    "The heterogeneity of the tribal population and absence of information related to them points towards an urgent need to consolidate data, information, practices and learnings for informed and evidence-based decision-making.",
  ABOUT_S2_TEXT_P2:
    "The Centre of Excellence (CoE) for Knowledge Management on Health and Nutrition, established by the Ministry of Tribal Affairs, aims to address this challenge by curating all data and information; thereby improving access to information related to health and nutrition of India’s tribal populations.",
  ABOUT_S2_TEXT_P4:
    "Developing and nurturing Swasthya is a core mandate of the CoE, among the following:",
  ABOUT_S2_TEXT_P4_B1:
    "Creating a consolidated data repository for tribal health & nutrition",
  ABOUT_S2_TEXT_P4_B2: "Facilitating evidence-based policy making ",
  ABOUT_S2_TEXT_P4_B3:
    "Documenting successful models, best practices and innovative solutions ",
  ABOUT_S2_TEXT_P4_B4: "Disseminating and facilitating exchange of knowledge",
  ABOUT_S2_TEXT_P4_B5:
    "Creating networks and collaborating with stakeholders to improve tribal health & nutrition outcomes",
};

export const CONTACT = {
  CONTACT: "Contact Us",
  QUESTIONS: "Have questions? <br />  Write to us",
  ADDRESS:
    "736, 7th Floor, A-Wing, <br /> Ministry of Tribal Affairs, <br /> Shastri Bhawan, New <br /> Delhi-110001",
  PHONE:
    "<a href='tel:+919875431'>91-9875431</a> <br/> <a href='tel:+0111234567'>011-1234567</a>",
  EMAIL:
    '<span href="mailto:aso-health-tribal[at]tribal[dot]gov[dot]in">aso-health-tribal[at]tribal[dot]gov[dot]in</span>',
  FULL_NAME: "Full Name",
  EMAIL_ADDRESS: "Email Address",
  PHONE_NO: "Phone/Mobile",
  MESSAGE: "Message",
  SUBMIT: "Submit",
};

export const RELATED_WEBSITES = {
  RELATED_WEBSITE: "Related Websites",
  RELATED_WEBSITE_TITLE:
    "Websites Related to Tribal Affairs, Health and Nutrition",
  RELATED_WEBSITE_TEXT: "List of relevant websites for additional information.",
};

export const PARTNER_ORGANIZATION = {
  TITLE: "Partners",
  HEADING: "Organisations Working on Tribal Health and Nutrition",
  CONTENT: `Several organisations are working <i>with</i> and <i>for</i> the tribal communities India, in the areas of health and nutrition. Through their contributions, they have been complementing the efforts of the government for years now. Given the topographic and cultural diversity of India, they have had unique experiences, used different approaches and designed innovative solutions to solve challenges related to health and nutrition of tribal communities.
        <br>
        <br>
        This section of Swasthya aims to map the geographic presence of organisations and showcase their work. While some organisations are partners of the Ministry of Tribal Affairs, working closely with the government to implement key programmes; others are complementing the efforts of the government through their own efforts and initiatives.
        <br>
        <br>
        <strong>
        It must be noted that the information presented in this section is <i>as received from the organisations</i>. The Ministry of Tribal Affairs does not endorse any organisation mentioned here.
        </strong>
        `,
};

export const ALEKH = {
  TITLE: "ALEKH",
  SUB_HEADING: "The Swasthya Sankalan Newsletter",
  ISSUE: "ISSUE 1 • AUGUST 2020",
  MESSAGE: "MESSAGE FROM THE MINISTER",
  MESSAGE_MINISTER:
    "The Ministry of Tribal Affairs is committed to the upliftment of the scheduled tribes of India. And the launch of Tribal Swasthya, the Tribal Health and Nutrition Portal on this Independence Day is a matter of pride to us today. Over the years, we have partnered with other Ministries, private organizations, academic institutions and more contribute towards having better education, livelihood, access to healthcare and increased opportunities for the communities to not only survive but thrive. This portal is one such efforts of the collective. I congratulate the team for actualizing the vision of curating the knowledge on tribal health and nutrition. I also extend my heartfelt gratitude to other Ministries, State and District Administrations, organizations partnering with the Ministry, other organizations working on the ground, experts and well wishers for actively supporting this initiative by sharing their insights, experience, learning and knowledge with us. I look forward to Swasthya’s optimal contribution towards better state of health and nutrition of the tribal communities of our nation.",
};

export const en = {
  ...ABOUTPAGE,
  ...NAVBAR,
  ...HOMEPAGE,
  ...FOOTER,
  ...RELATED_WEBSITES,
  ...CONTACT,
  ...LOGIN,
  ...PARTNER_ORGANIZATION,
  ...ALEKH,
  ...LOGOUT,
};
