import "./messageboard.scss";

import React from "react";

export default function MessageBoard(props) {
  return (
    <div className="card mt-4 message-container">
      <div className="card-body">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
            <figure className="message-figure">
              <img
                src={require("../../assets/images/message-img.jpg")}
                alt="Imageeee"
                className="img-fluid"
              />
            </figure>
          </div>
          <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12 m-auto">
            <div className="message-content-box text-justify">
              <h6>
                <b> Message from the Minister’s Desk </b>
              </h6>
              <p>
                The Ministry of Tribal Affairs is committed to improving the
                health and wellbeing of the tribal population of India. I am
                happy to launch Swasthya, a knowledge portal which curates
                information from different sources pertaining to tribal health
                and nutrition. This endeavour is symbolic of the cumulative
                efforts being made by us for the achievement of our core vision.
                With this, the issue of tribal health will be brought in the
                forefront. I am certain that the information available here will
                accelerate decision making and inform policies and programs for
                tribal health and nutrition in the coming days. I look forward
                to Swasthya’s optimal contribution towards bettering the lives
                of the tribal people in India.
              </p>
              <p className="text-right mb-0">
                {" "}
                - Shri Arjun Munda, Hon’ble Minister of Tribal Affairs
              </p>
            </div>
          </div>{" "}
          {/* Col End   */}
        </div>{" "}
        {/* Row End */}
      </div>{" "}
      {/* Card Body End   */}
    </div> // Card End
  );
}
