import { FORM_DETAILS } from "./endpoints";
import React from "react";
import { BASE_URL } from "./index";

const loginRequestOptions = {
  deviceId: "test",
  oSVersion: "test",
  appVersion: "test",
  dbVersion: "test",
  lng: 72.5623,
  lat: 20.898656,
  firebaseToken: "test",
};

const getToken = () => localStorage.getItem("token");
const getRole = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : "";

async function postData(
  url = "",
  data = {},
  method = "POST",
  contentType = "application/json"
) {
  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": contentType,
      "x-access-token": localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  });
  try {
    return await response.json();
  } catch (e) {
    return e;
  }
}

async function getData(url, options = {}) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": `application/json`,
      "x-access-token": getToken(),
      role: getRole(),
    },
  });
  try {
    return await (await response).json();
  } catch (e) {
    return e;
  }
}

async function getFormDetails(formId, config = {}) {
  const url = `${BASE_URL}${FORM_DETAILS}/${formId}`;
  /*	const fakeUrl = require("../assets/dummy2");
        return new Promise((r) => {
            r(fakeUrl);
        });*/

  const response = fetch(url, {
    headers: {
      "Content-Type": `application/json`,
      "x-access-token": getToken(),
    },
  });
  try {
    return await (await response).json();
  } catch (e) {
    return e;
  }
}

export { postData, getFormDetails, getData, loginRequestOptions };
