import React from "react";

import {
  SET_DISTRICT,
  SET_SELECTED_STATE,
  SET_STATE_ONLY_VIEW,
} from "../reducers/constants";

export const MapDataContext = React.createContext();
export const MapDispatchContext = React.createContext();

const initialState = {
  selectedState: null,
  selectedDistrict: [],
  geoType: "india",
  stateOnly: false,
  districtOnly: false,
};
let count = 0;

function DashboardReducer(state, action) { 
  switch (action.type) {
    case SET_DISTRICT:
      return {
        ...state,
        geoType: "district",
        districtOnly: true,
        selectedDistrict: [...action.payload],
      };
    case SET_SELECTED_STATE:
      return {
        ...state,
        geoType: action.payload
          ? state.districtOnly
            ? state.geoType
            : "state"
          : "india",
        selectedState: action.payload || null,
        selectedDistrict: [],
        stateOnly: true,
      };
    case SET_STATE_ONLY_VIEW:
      return {
        ...state,
        geoType: action.payload ? "state" : "india",
        selectedDistrict: [],
        selectedState: action.payload,
        stateOnly: !!action.payload,
        districtOnly: false,
      };
    default:
      return state;
  }
}

export function MapProvider({ children }) { 
  const [state, dispatch] = React.useReducer(DashboardReducer, initialState);
  return (
    <MapDataContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapDataContext.Provider>
  );
}
