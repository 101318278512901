import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./index.scss";

export default function PartnerLogos() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        showDots={true}
        removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
    >
        <div><a href="https://aajeevika.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/aajeevika.png")} className="img-fluid" /></a></div>
        <div><a href="https://www.cftri.res.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/Csir-cftri.png")} className="img-fluid" /></a></div>
        <div><a href="http://goal.tribal.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/goal.png")} className="img-fluid" /></a></div>
        <div><a href="https://mhrd.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/mhrd.png")} className="img-fluid" /></a></div>
        <div><a href="https://www.mygov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/my-gov.png")} className="img-fluid" /></a></div>
        <div><a href="https://ncst.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/ncst.png")} className="img-fluid" /></a></div>
        <div><a href="https://www.nic.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/nic.png")} className="img-fluid" /></a></div>
        <div><a href="https://niti.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/niti-ayog.png")} className="img-fluid" /></a></div>
        <div><a href="https://nhm.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/nrhm.png")} className="img-fluid" /></a></div>
        <div><a href="https://nrlm.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/nrlm.png")} className="img-fluid" /></a></div>
        <div><a href="http://www.nstfdc.net/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/nstfdc.png")} className="img-fluid" /></a></div>
        <div><a href="https://www.panchayat.gov.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/panchayati-raj.png")} className="img-fluid" /></a></div>
        <div><a href="https://icds-wcd.nic.in/nnm/home.htm" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/poshan-abhiyaan.png")} className="img-fluid" /></a></div>
        <div><a href="https://www.tribesindia.com/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/tribe-india.png")} className="img-fluid" /></a></div>
        <div><a href="https://www.iipsindia.ac.in/" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/iips.png")} className="img-fluid" /></a></div>
    </Carousel>;
}