export const converDateToDayMonthYear = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "/" + mm + "/" + yyyy;
};

export const aashwasanHeatmapCSVHeaders = [
  { label: "Name", key: "name" },
  { label: "State Name", key: "state_name" },
  { label: "No. of Blocks covered", key: "total_blocks_covered" },
  { label: "No. of Villages covered", key: "total_villages_covered" },
  { label: "No. of Districts covered", key: "total_districts_covered" },
  { label: "No. of People reached", key: "total_people_reached" },
  {
    label: "No. of Persons Screened for TB",
    key: "total_people_screened_for_tb",
  },
  {
    label: "No. of Persons with Presumptive TB",
    key: "total_people_with_persumptive_tb",
  },
  {
    label: "No. of Presumptive TB Cases whose Samples are Tested",
    key: "total_people_tested_for_tb",
  },
  {
    label: "No. of Persons Tested Positive for TB",
    key: "total_positive_tb_cases",
  },
  {
    label: "No. of TB Positive Started on Treatment",
    key: "total_positive_persons_started_on_treatment",
  },
  { label: "As on", key: "as_on_date" },
];

export const convertIndicatorsName = (nameKey) => {
  switch (nameKey) {
    case "total_people_reached":
      return "No. of people reached";
    case "total_districts_covered":
      return "No. of Districts Covered";
    case "total_blocks_covered":
      return "No. of Blocks Covered";
    case "total_people_screened_for_tb":
      return "No. of Persons Screened for TB";
    case "total_people_with_persumptive_tb":
      return "No. of Persons with Presumptive TB";
    case "total_people_tested_for_tb":
      return "No. of Presumptive TB Cases whose Samples are Tested";
    case "total_positive_tb_cases":
      return "No. of Persons Tested Positive for TB";
    case "total_positive_persons_started_on_treatment":
      return "No. of TB Positive Started on Treatment";
    default:
      return "";
  }
};

export const s3UploadFile = async (file) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  let requestOptions = {
    method: "post",
    body: JSON.stringify([
      {
        file_name: file.name,
        mime_type: file.type,
      },
    ]),
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-access-token": getToken(),
    // },
  };
  let url = `${baseUrl}getSignedUrl`;
  let responceUrl = await fetch(url, requestOptions);
  if (responceUrl.success) {
    let putOptions = {
      method: "put",
      body: file,
      headers: {
        "Content-Type": file.type,
        excludeContentHeader: true,
        // "x-access-token": getToken(),
      },
    };
    let response = await fetch(responceUrl["data"]["url"], putOptions);
    if (response.status === 403) {
      let obj = { success: false, message: response.statusText };
      return obj;
    } else {
      return { success: true, url: responceUrl["data"]["file_url"] };
    }
  } else {
    return { success: false, message: responceUrl.message };
  }
};
