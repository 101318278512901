import React, {useRef, useLayoutEffect} from 'react'


const isBrowser = typeof window !== `undefined`

function getScrollPosition({element, useWindow}) {
    if (!isBrowser) return {x: 0, y: 0}

    const target = element ? element.current : document.body
    const position = target.getBoundingClientRect()

    return useWindow
        ? {x: window.scrollX, y: window.scrollY}
        : {x: position.left, y: position.top}
}

export const useFetch = (url = '', {defaultValue = [], when = true} = {}, options = {}) => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(defaultValue);
    const [error, setError] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    React.useEffect(() => {
        async function fetchData() {
            setData(defaultValue);
            if (!url) {

            } else {
                if (when) {
                    setLoading(true);
                    const headers = new Headers({'x-access-token': localStorage.getItem("token")});
                    let user = localStorage.getItem('user');
                    if (user) {
                        user = JSON.parse(user);
                        headers.append('role', user.role);
                    }
                    try {
                        let response = await fetch(`${url}`, {headers});
                        response = await response.json();
                        if (response.success) {
                            setError(null);
                            setData(response.data);
                        } else setError(response)
                        setLoading(false);
                    } catch (e) {
                        setError(e);
                        setLoading(false);
                    }
                }
            }
        }

        fetchData();
    }, [url, when, reload]);
    return [loading, data, error, {reload: () => setReload(r => !r), url}];

}

export function useScrollPosition(effect, deps, element, useWindow, wait = 100) {
    const position = useRef(getScrollPosition({useWindow}))

    let throttleTimeout = null

    const callBack = () => {
        const currPos = getScrollPosition({element, useWindow})
        effect({prevPos: position.current, currPos})
        position.current = currPos
        throttleTimeout = null
    }

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout === null) {
                    throttleTimeout = setTimeout(callBack, wait)
                }
            } else {
                callBack()
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, deps)
}
