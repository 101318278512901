import React from "react";
import { Carousel } from "react-bootstrap";
import "./index.scss";

export default function AashwanCarousel() {
  return (
    <Carousel>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/Banner1.png") + ")",
          backgroundSize: window.innerWidth < 1700 ? "cover" : "contain",
        }}
      ></Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/banner2.png") + ")",
        }}
      ></Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/Banner3.png") + ")",
        }}
      ></Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/banner4.png") + ")",
        }}
      ></Carousel.Item>
    </Carousel>
  );
}
