import React from "react";
import { Carousel } from "react-bootstrap";
import "./index.scss";
import { HOMEPAGE } from "../../assets/strings/en";

export default function HomeCarousel() {
  return (
    <Carousel>
      {/* <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/mann-ki-baat_02.jpg") + ")",
          backgroundSize: window.innerWidth < 1700 ? "cover" : "contain",
        }}
      >
        <a
          href="http://rashtragaan.in/"
          target="_blank"
          style={{
            bottom: 0,
            position: "absolute",
            left: window.innerWidth < 1700 ? "30vw" : "36vw",
          }}
        >
          Rashtra Gaan
        </a>
      </Carousel.Item> */}
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/DSC_7103.JPG") + ")",
        }}
      ></Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/images/dawai-bhi-kadhai-bhi.jpg") +
            ")",
        }}
      ></Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/images/Banner_1_MoTA_Revised_Mustard.png") +
            ")",
        }}
      >
        {/*  <div class="container">
                <Carousel.Caption>
                    <div>{HOMEPAGE.SLIDE_TEXT1}</div>
                </Carousel.Caption> 
            </div>  */}
      </Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/banner-new-3.png") + ")",
        }}
      >
        {/* <Carousel.Caption>
                <div>{HOMEPAGE.SLIDE_TEXT2} </div> 
            </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/images/Banner_2_MoTA_Revised_Mustard.png") +
            ")",
        }}
      >
        {/* <Carousel.Caption>
                <div>{HOMEPAGE.SLIDE_TEXT3} </div>
            </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item
        className="bg-bottom"
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/Newsletter_Banner.png") + ")",
        }}
      >
        {/* <Carousel.Caption>
                <div>{HOMEPAGE.SLIDE_TEXT3} </div>
            </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/Banner_4_MoTA.png") + ")",
        }}
      >
        {/* <Carousel.Caption>
                <div>{HOMEPAGE.SLIDE_TEXT3} </div>
            </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}
