import React from "react";
import {Modal} from "react-bootstrap";


export function ModalDialog({header, children, footer, show, onHide, size = "lg"}) {
    return (
        <Modal size={size} onHide={onHide} show={show} centered>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {footer && <Modal.Footer>
                {footer}
            </Modal.Footer>}
        </Modal>
    )

}
