import "./index.scss";

import * as L from "leaflet";
import React from "react";
import { renderToString } from "react-dom/server";
import { SVGMap } from "react-svg-map";

import data from "../../assets/data";
import boundaries from "../../assets/json/states_with_district_boundaries.json";
import {
  MapDataContext,
  MapDispatchContext,
} from "../../contexts/dashboard-map";
import { SET_DISTRICT, SET_STATE_ONLY_VIEW } from "../../reducers/constants";
import { getPosition } from "../../utils";
import { Tooltip } from "../tooltip";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";

const layerStyle = {
  color: "#808080",
  fill: true,
  fillColor: "#fff",
  weight: 0.6,
  opacity: 1,
  fillOpacity: 1,
};

function AashwasanHeatmap({
  states = [],
  districts = [],
  csvHeaders,
  districtCSV,
}) {
  const { selectedState, stateOnly, districtOnly, selectedDistrict, geoType } =
    React.useContext(MapDataContext);
  const dispatch = React.useContext(MapDispatchContext);
  const mapContainer = React.useRef();
  const currentLayer = React.useRef();
  const [currentPathId, setPath] = React.useState(null);
  const [hoveredState, setHoverState] = React.useState(null);

  const tooltipElement = (
    <>
      <h5 className="text-center text-secondary">
        {hoveredState && hoveredState.name}
      </h5>
      <table className="table table-sm mb-0">
        <tbody>
          <tr>
            <td>No. of Districts Covered</td>
            <td>{hoveredState && hoveredState.total_districts_covered}</td>
          </tr>
          <tr>
            <td>No. of Persons Screened for TB</td>
            <td>{hoveredState && hoveredState.total_people_screened_for_tb}</td>
          </tr>
          <tr>
            <td>No. of Presumptive TB Cases whose Samples are Tested</td>
            <td>
              {hoveredState && hoveredState.total_people_with_persumptive_tb}
            </td>
          </tr>
          <tr>
            <td>No. of Persons with Presumptive TB</td>
            <td>{hoveredState && hoveredState.total_people_tested_for_tb}</td>
          </tr>
          <tr>
            <td>No. of Persons Tested Positive for TB</td>
            <td>{hoveredState && hoveredState.total_positive_tb_cases}</td>
          </tr>
          <tr>
            <td>No. of TB Positive Started on Treatment</td>
            <td>
              {hoveredState &&
                hoveredState.total_positive_persons_started_on_treatment}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  React.useEffect(() => {
    if (geoType === "india") {
      mapContainer.current = null;
    }
  }, [geoType, selectedState, stateOnly]);

  React.useEffect(() => {
    if (document.getElementById("mapid5")) {
      if (!mapContainer.current) {
        mapContainer.current = L.map("mapid5", {
          preferCanvas: false,
          zoomControl: false,
          doubleClickZoom: false,
          touchZoom: false,
          scrollWheelZoom: false,
          zoom: false,
          dragging: false,
          minZoom: 4,
          zoomSnap: 0.25,
          zoomDelta: 0.25,
        });
      } else {
        mapContainer.current.removeLayer(currentLayer.current);
        if (mapContainer.current) {
          setTimeout(() => {
            mapContainer.current.invalidateSize();
          }, 300);
        }
      }
      const stateBoundaries = boundaries.features.filter(
        (state) =>
          Number(state.properties.ST_CEN_CD) === Number(selectedState.code)
      );

      if (stateBoundaries.length) {
        currentLayer.current = L.geoJSON(
          {
            ...boundaries,
            features: stateBoundaries,
          },
          {
            style: (feature) => {
              const district = districts.find(
                (d) => d.name === feature.properties.DISTRICT
              );

              return {
                ...layerStyle,
                color:
                  district && district.total_blocks_covered !== null
                    ? "#004B4E"
                    : "#fff",
                weight: selectedDistrict.find(
                  (d) =>
                    +d.code === +feature.properties.DT_CEN_CD ||
                    d.name == feature.properties.DISTRICT
                )
                  ? "4"
                  : 1,
                fillColor:
                  district && district.total_blocks_covered !== null
                    ? "#ffff00"
                    : "#ccc",
              };
            },
            onEachFeature: (feature, layer) => {
              layer.on({
                click: (layer) => {
                  const foundDist1 = districts.find(
                    (d) => d.name === feature.properties.DISTRICT
                  );
                  dispatch({
                    type: SET_DISTRICT,
                    payload: [foundDist1],
                  });
                },
              });

              const foundDist = districts.find(
                (d) => d.name === feature.properties.DISTRICT
              );

              if (foundDist && foundDist.total_blocks_covered !== null) {
                layer.bindTooltip(
                  renderToString(
                    <>
                      <h5 className="text-center text-secondary">
                        {foundDist.name}
                      </h5>
                      <table className="table table-sm mb-0">
                        <tbody>
                          <tr>
                            <td>No. of Blocks Covered</td>
                            <td>{foundDist.total_blocks_covered}</td>
                          </tr>
                          <tr>
                            <td>No. of Persons Screened for TB</td>
                            <td>{foundDist.total_people_screened_for_tb}</td>
                          </tr>
                          <tr>
                            <td>
                              No. of Presumptive TB Cases whose Samples are
                              Tested
                            </td>
                            <td>
                              {foundDist.total_people_with_persumptive_tb}
                            </td>
                          </tr>
                          <tr>
                            <td>No. of Persons with Presumptive TB</td>
                            <td>{foundDist.total_people_tested_for_tb}</td>
                          </tr>
                          <tr>
                            <td>No. of Persons Tested Positive for TB</td>
                            <td>{foundDist.total_positive_tb_cases}</td>
                          </tr>
                          <tr>
                            <td>No. of TB Positive Started on Treatment</td>
                            <td>
                              {
                                foundDist.total_positive_persons_started_on_treatment
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )
                );
              } else {
                L.Util.setOptions(layer, {
                  interactive: false,
                });
                layer.setStyle({
                  className: "nonTribal",
                });
              }
            },
          }
        ).addTo(mapContainer.current);
        mapContainer.current.fitBounds(currentLayer.current.getBounds());
        if (mapContainer.current) {
          setTimeout(() => {
            mapContainer.current.invalidateSize();
          }, 300);
        }
      }
    }
  }, [dispatch, districts, selectedDistrict, selectedState, stateOnly]);

  React.useEffect(() => {
    if (mapContainer.current) {
      setTimeout(() => {
        mapContainer.current.invalidateSize();
      }, 300);
    }
  }, [districtOnly]);

  React.useEffect(() => {
    let path = "";
    if (hoveredState) {
      path = data.locations.find(
        (location) =>
          location.id.toUpperCase() === hoveredState.name_key.toUpperCase()
      );
    }
    if (selectedState) {
      path = data.locations.find(
        (location) =>
          location.id.toUpperCase() === selectedState.name_key.toUpperCase()
      );
    }
    if (path) {
      setPath(path.id);
    } else {
      setPath(null);
    }
  }, [selectedState, hoveredState]);

  React.useEffect(() => {
    data.locations.forEach((path) => {
      let element = document.getElementById(path.id);
      if (element) {
        element.classList.remove("selected");
      }
    });
    const pathElement = document.getElementById(currentPathId);
    if (pathElement) {
      pathElement.classList.add("selected");
    }
  }, [currentPathId, stateOnly, selectedState, states]);

  React.useEffect(() => {
    states.forEach((state) => {
      let element = document.getElementById(state.name_key);
      if (element) {
        if (state.total_blocks_covered !== null) {
          element.classList.add("allSates");
          element.style.fill = "#ffff00";
        }
      }
    });
  }, [states, stateOnly]);

  let containerRef = React.useRef(null);
  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("mouseleave", (e) => {
        setHoverState(null);
      });
    }
  }, []);

  function onHover(event) {
    const { id } = event.target;

    const foundState = states.find(
      (state) => state.name_key === id.toUpperCase()
    );
    setHoverState(foundState);
  }

  function onLocationClicked(element) {
    const { id } = element.target;
    const foundState = states.find(
      (state) => state.name_key === id.toUpperCase()
    );
    // if (foundState.code === 'LDK') return;
    dispatch({ type: SET_STATE_ONLY_VIEW, payload: foundState });
  }
  if (geoType === "india") {
    return (
      <div key={"svg-map"} ref={containerRef} id="svg-map">
        <Button variant="secondary">
          <CSVLink
            style={{ color: "white" }}
            headers={csvHeaders}
            data={districtCSV}
            filename="Districts.csv"
          >
            Download Districts
          </CSVLink>
        </Button>
        <Tooltip
          children={tooltipElement}
          position={getPosition(document.getElementById(currentPathId))}
        />
        <SVGMap
          onLocationMouseOver={onHover}
          onLocationClick={onLocationClicked}
          locationClassName={"nonTribal"}
          role="d"
          id="svg-map"
          map={data}
        />
      </div>
    );
  }

  if (geoType === "state" || geoType === "district") {
    return (
      <div>
        <Button variant="secondary">
          <CSVLink
            style={{ color: "white" }}
            headers={csvHeaders}
            data={districtCSV}
            filename="Districts.csv"
          >
            Download Districts
          </CSVLink>
        </Button>
        <div key={"leaflet-map"} id="mapid5" />
      </div>
    );
  }

  return null;
}

export default AashwasanHeatmap;
