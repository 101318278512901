import "./index.scss";
import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import { DashboardCardSmall } from "../../components/dashboard-card";
import Loader from "../../components/loader";
import {
  MapDataContext,
  MapDispatchContext,
} from "../../contexts/dashboard-map";
import { useFetch } from "../../hooks";
import {
  SET_DISTRICT,
  SET_SELECTED_STATE,
  SET_STATE_ONLY_VIEW,
} from "../../reducers/constants";
import { getApiWithLanguageParams } from "../../services";
import { scrollLeft } from "../../utils";
import AashwasanHeatmap from "../../components/map/aashwasanHeatmap";
import DashboardCardForDistrict from "../../components/dashboard-card/DashboardCardForDistrict";
import DistrictMap from "../../components/map/DistrictMap";
import DistrictMapDetails from "../../components/map/DistrictMapDetails";
import {
  aashwasanHeatmapCSVHeaders,
  converDateToDayMonthYear,
  convertIndicatorsName,
} from "../../utils/helpers";

const excludedKeys = [
  "id",
  "name",
  "state_id",
  "district_name",
  "total_people_reached",
  "total_villages_covered",
];

function AashwasanDashboard({ districtRes }) {
  const { selectedState, geoType, stateOnly, selectedDistrict, districtOnly } =
    React.useContext(MapDataContext);
  const dispatch = React.useContext(MapDispatchContext);

  const [visibleIndicators, setVisibleIndicators] = React.useState([]);
  const [showDistrictMap, setShowDistrictMap] = React.useState(false);

  const [indicatorLoading, indicators] = useFetch(
    getApiWithLanguageParams(
      `aashwasanMapIndicators?stateCode=${selectedState?.id || ""}`
    )
  );

  const [stateLoading, states] = useFetch(
    getApiWithLanguageParams(`aashwasanStateWithIndicators`)
  );

  const [csvDataLoading, csvData] = useFetch(
    getApiWithLanguageParams(
      `aashwasanDistrictsWithIndicators?stateId=${selectedState?.id || ""}`
    )
  );

  const [districtDataLoading, districtsData] = useFetch(
    !!selectedState
      ? getApiWithLanguageParams(
          `aashwasanDistrictsWithIndicators?stateId=${selectedState.id}`
        )
      : null,
    {
      defaultValue: [],
      when: selectedState && stateOnly && states.length,
    }
  );

  function handleStateChange(event) {
    const value = event.target.value;
    if (value === "all_districts") {
      setShowDistrictMap(true);
      return;
    }
    const selectedState = states.find((state) => +state.id === +value);

    dispatch({ type: SET_SELECTED_STATE, payload: selectedState });
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const getTotalFromArrOfObjects = (arr) => {
    const obj = arr.reduce((acc, current) => ({
      total_blocks_covered:
        +acc.total_blocks_covered + +current.total_blocks_covered,
      total_districts_covered:
        +acc.total_districts_covered + +current.total_districts_covered,
      total_people_screened_for_tb:
        +acc.total_people_screened_for_tb +
        +current.total_people_screened_for_tb,
      total_people_with_persumptive_tb:
        +acc.total_people_with_persumptive_tb +
        +current.total_people_with_persumptive_tb,
      total_people_tested_for_tb:
        +acc.total_people_tested_for_tb + +current.total_people_tested_for_tb,
      total_positive_tb_cases:
        +acc.total_positive_tb_cases + +current.total_positive_tb_cases,
      total_positive_persons_started_on_treatment:
        +acc.total_positive_persons_started_on_treatment +
        +current.total_positive_persons_started_on_treatment,
    }));

    if (geoType === "india") {
      delete obj.total_blocks_covered;
    } else {
      delete obj.total_districts_covered;
    }

    return obj;
  };

  React.useEffect(() => {
    if (indicators.length) {
      setVisibleIndicators(getTotalFromArrOfObjects(indicators));
    } else {
      setVisibleIndicators({
        total_blocks_covered: 0,
        total_districts_covered: 0,
        total_people_screened_for_tb: 0,
        total_people_with_persumptive_tb: 0,
        total_people_tested_for_tb: 0,
        total_positive_persons_started_on_treatment: 0,
        total_positive_tb_cases: 0,
      });
    }
  }, [indicators]);

  // React.useEffect(() => {
  //   console.log("showDistrictMap: ", showDistrictMap);
  // }, [showDistrictMap]);

  const tempIcon =
    "https://stgmotaapi.dhwaniris.in/objects/ef50e2c7-9414-4057-b261-4783a8360fb4.svg";
  return (
    <>
      {showDistrictMap ? (
        <Card className="mb-4">
          <Card.Body>
            <span
              role="button"
              className="d-flex m-1"
              onClick={() => setShowDistrictMap(false)}
            >
              <span className="material-icons">arrow_back</span>
              Go back
            </span>
            <Row className="align-items-center">
              <Col sm="6">
                <DistrictMap districts={districtRes} />
              </Col>
              <Col sm="6">
                <DistrictMapDetails />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Row>
              <Col sm={geoType === "district" ? "5" : "6"}>
                <Row>
                  <Col xs={12}>
                    {geoType === "district" && (
                      <Row>
                        <span
                          role="button"
                          className="d-flex m-1"
                          onClick={() =>
                            dispatch({
                              type: SET_STATE_ONLY_VIEW,
                              payload: selectedState,
                            })
                          }
                        >
                          <span className="material-icons">arrow_back</span>
                        </span>

                        <Form.Group className={"col"}>
                          <Form.Control
                            disabled={stateLoading}
                            value={(selectedState && selectedState.id) || ""}
                            onChange={handleStateChange}
                            as="select"
                          >
                            <option disabled={stateOnly} value={null}>
                              All India - State
                            </option>
                            <option value="all_districts">
                              All India - Districts
                            </option>
                            {states
                              .filter(
                                (state) => state.total_blocks_covered !== null
                              )
                              .map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        {districtOnly && (
                          <Form.Group className={"col"}>
                            <MultiSelect
                              hasSelectAll={true}
                              value={selectedDistrict.map((d) => {
                                return {
                                  ...d,
                                  label: d.name,
                                  value: d.id,
                                };
                              })}
                              onChange={(values) => {
                                dispatch({
                                  type: SET_DISTRICT,
                                  payload: values,
                                });
                              }}
                              labelledBy={"Select"}
                              options={districtsData
                                .filter(
                                  (dist) => dist.total_blocks_covered !== null
                                )
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((d) => {
                                  return {
                                    ...d,
                                    label: d.name,
                                    value: d.id,
                                  };
                                })}
                            />
                          </Form.Group>
                        )}
                      </Row>
                    )}
                  </Col>

                  <Col xs={12}>
                    {geoType === "state" && (
                      <span
                        role="button"
                        className="d-flex"
                        onClick={() =>
                          dispatch({
                            type: SET_STATE_ONLY_VIEW,
                            payload: false,
                          })
                        }
                      >
                        <span className="material-icons">arrow_back</span>
                        Back to All States{" "}
                      </span>
                    )}
                    <AashwasanHeatmap
                      states={states}
                      districts={districtsData}
                      csvHeaders={aashwasanHeatmapCSVHeaders}
                      districtCSV={csvData?.filter(
                        (c) => c.total_blocks_covered !== null
                      )}
                    />
                  </Col>
                </Row>
              </Col>

              <Col>
                {!districtOnly && (
                  <Form.Group>
                    <Form.Control
                      value={(selectedState && selectedState.id) || ""}
                      onChange={handleStateChange}
                      as="select"
                    >
                      <option disabled={stateOnly} value={null}>
                        All India - State
                      </option>
                      <option value="all_districts">
                        All India - Districts
                      </option>
                      {states
                        .filter((state) => state.total_blocks_covered !== null)
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                )}

                <h3 className="text-center my-4">
                  {selectedDistrict.length
                    ? `${selectedDistrict[0].name} (${selectedState.name})`
                    : !!selectedState
                    ? selectedState.name
                    : "India"}
                </h3>

                {indicatorLoading || districtDataLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <div
                    style={{
                      display: `${
                        selectedDistrict.length > 1 ? "block" : "grid"
                      }`,
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    {!districtOnly &&
                      visibleIndicators &&
                      Object.keys(visibleIndicators).map((k) => {
                        if (!excludedKeys.includes(k)) {
                          return (
                            <DashboardCardForDistrict
                              key={k}
                              imageUrl={tempIcon}
                              title={convertIndicatorsName(k)}
                              description={visibleIndicators[
                                k
                              ].toLocaleString()}
                            />
                          );
                        }
                      })}
                    {selectedDistrict.length === 1 &&
                      Object.keys(selectedDistrict[0]).map((k) => {
                        if (
                          !excludedKeys.includes(k) &&
                          k !== "total_districts_covered" &&
                          k !== "state_name" &&
                          k !== "as_on_date" &&
                          k !== "label" &&
                          k !== "value"
                        ) {
                          return (
                            <DashboardCardForDistrict
                              key={k}
                              imageUrl={tempIcon}
                              title={convertIndicatorsName(k)}
                              description={selectedDistrict[0][k]}
                            />
                          );
                        }
                        return null;
                      })}

                    {selectedDistrict.length > 1 && (
                      <Row noGutters>
                        <Col xs="auto" className="mt-4">
                          <img
                            alt="icon"
                            src={require("../../assets/icons/arrow-left.svg")}
                            className="img-fluid cursor-hand"
                            id="left-button"
                            onClick={() =>
                              scrollLeft(
                                document.getElementById("scroll-div-top"),
                                -300,
                                1000
                              )
                            }
                          />
                        </Col>
                        <Col>
                          <div id="scroll-div-top" className="scroll-div">
                            <Row>
                              <Col sm="5">Indicators</Col>
                              {selectedDistrict.map((d, index) => (
                                <Col
                                  style={{
                                    // border: '1px solid #004B4E',
                                    background: "#008080",
                                    color: "white",
                                  }}
                                  key={index}
                                >
                                  {d.name}
                                </Col>
                              ))}
                            </Row>
                            {Object.keys(
                              getTotalFromArrOfObjects(selectedDistrict)
                            ).map((k) => {
                              if (
                                !excludedKeys.includes(k) &&
                                k !== "total_districts_covered"
                              ) {
                                return (
                                  <Row key={k}>
                                    <Col sm="5">
                                      <DashboardCardSmall
                                        label={convertIndicatorsName(k)}
                                      />
                                    </Col>
                                    {selectedDistrict.map((dist, i) => (
                                      <Col key={i}>{dist[k]}</Col>
                                    ))}
                                  </Row>
                                );
                              }
                            })}
                          </div>
                        </Col>
                        <Col xs="auto" className="mt-4">
                          <img
                            alt="imagee"
                            src={require("../../assets/icons/arrow-right.svg")}
                            className="img-fluid cursor-hand"
                            id="right-button"
                            onClick={() =>
                              scrollLeft(
                                document.getElementById("scroll-div-top"),
                                300,
                                1000
                              )
                            }
                          />
                        </Col>
                      </Row>
                    )}
                    <p>
                      As on:{" "}
                      {converDateToDayMonthYear(
                        geoType === "india"
                          ? indicators[0]?.as_on_date || null
                          : geoType === "state"
                          ? selectedState.as_on_date.split(",")[0] || null
                          : selectedDistrict[0]?.as_on_date || null
                      )}
                    </p>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "0.8rem",
                    marginBottom: "0",
                    fontWeight: "bold",
                  }}
                >
                  Note:
                </p>
                <p
                  style={{
                    fontSize: "0.8rem",
                    marginBottom: "0",
                  }}
                >
                  *Data represented is only of the Aashwasan campaign and not of
                  the overall TB related data of the district
                </p>
                <p style={{ fontSize: "0.8rem" }}>
                  *The Aashwasan campaign across 174 districts is closed as on
                  31st August 2022
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default AashwasanDashboard;
