import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AashwanCarousel from "../../components/aashwan-carousel";
import Glimpses from "../../components/glimpses";
import ImportantDocs from "../../components/important-docs";
import PerspectiveTbMap from "../../components/map/PerspectiveTbMap";
import TBPositiveRateMap from "../../components/map/TBPositiveRateMap";
import { useFetch } from "../../hooks";
import { getApiWithLanguageParams } from "../../services";
import { MapProvider } from "../../contexts/dashboard-map";
import AashwasanDashboard from "../dashboard/aashwasanDashboard";

const Aashwasan = () => {
  const [districtLoading, districtRes] = useFetch(
    getApiWithLanguageParams("district?is_tribal=1")
  );
  return (
    <div>
      <AashwanCarousel />
      <section className="page-bg py-4">
        <Container>
          <Card className="mb-4">
            <Card.Body>
              <Row className="align-items-center">
                <Col sm="6">
                  <h2 className="title">Tribal TB Initiative</h2>
                  <p>
                    The <strong>Tribal TB Initiative</strong> was jointly
                    launched by the Ministry of Tribal Affairs and the Ministry
                    of Health and Family Welfare on 26th March 2021 to address
                    TB among the tribal communities of India; who are known to
                    have a higher prevalence of the disease compared to the rest
                    of the population. The initiative brings together
                    stakeholders with diverse capabilities to tackle the
                    multi-dimensional issues specific to TB prevalent among the
                    tribal communities. The Initiative focuses on strengthening
                    collaboration between donors, governments and partners to
                    strengthen primary health care service delivery from a
                    system’s perspective, building a strong technology &
                    information backbone, and providing support to the health
                    workforce in the tribal areas. At the same time, it seeks to
                    strengthen governance, financing, supply-chain and
                    infrastructure to obtain the goals of the National
                    Tuberculosis Elimination Programme (NTEP). .
                  </p>
                </Col>
                <Col sm="6">
                  <img
                    src={require("../../assets/images/imagee.jpg")}
                    className="img-fluid"
                    alt="imggg"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Body>
              <Row className="align-items-center">
                <Col sm="6">
                  <h2 className="title">
                    Aashwasan: Joint Campaign on COVID & ACF for TB
                  </h2>
                  <p>
                    TB prevalence among the tribal communities is estimated to
                    be 703 per 100,000, as against the national average of 256
                    per 100,000. Despite this, out of all TB notified patients,
                    only 10.4% belong to the tribal communities. The reasons for
                    low notification among the tribal population include
                    remoteness of the tribal habitations (hard-to-reach areas)
                    and their distance from health/ diagnostic facilities,
                    unavailability of diagnostic machines at the point of care,
                    and differences in the presentation of clinical signs &
                    symptoms among tribal communities. Evidence suggests that
                    the COVID-19 pandemic has impacted the TB notification rates
                    across the country, with the tribal districts witnessing a
                    significant reduction in TB notification. Further, despite
                    record vaccination coverage in India, the tribal districts
                    are lagging behind in comparison to other Districts of
                    India. Timely management of cases, behaviour change to
                    promote COVID Appropriate Behaviour (CAB) and addressing
                    vaccine hesitancy, myths and misconceptions related to COVID
                    are imperative to curb the spread of the disease and
                    minimize the impact of future waves. With this background,
                    the Ministry of Tribal Affairs, together with the Central TB
                    Division, Ministry of Health & Family, has launched
                    <strong> Aashwasan</strong>, a{" "}
                    <strong>
                      100 Day Joint Campaign to Address COVID-19 and ACF for TB
                      in all Tribal Districts of India.
                    </strong>
                  </p>
                </Col>
                <Col sm="6">
                  <img
                    alt="kdjf"
                    src={require("../../assets/images/imagee1.jpg")}
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Body>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                Campaign Dashboard
              </h3>
              <MapProvider>
                <AashwasanDashboard districtRes={districtRes} />
              </MapProvider>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Body>
              <Row className="align-items-center">
                <Col sm="6">
                  <PerspectiveTbMap />
                </Col>
                <Col sm="6">
                  <TBPositiveRateMap />
                </Col>
              </Row>
              <p style={{ marginBottom: "0", fontWeight: "bold" }}>Note:</p>
              <p style={{ marginBottom: "0" }}>
                *Data represented is only of the Aashwasan campaign and not of
                the overall TB related data of the district
              </p>
              <p>
                *The Aashwasan campaign across 174 districts is closed as on
                31st August 2022
              </p>
            </Card.Body>
          </Card>
        </Container>
        <Container>
          <Row>
            <Col sm="6" className="mb-3 mb-sm-0">
              <Glimpses />
            </Col>
            <Col sm="6">
              <ImportantDocs />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Aashwasan;
