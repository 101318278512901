import React from "react";
import "./loader.scss"

export default function Loader(props) {

    return (
        <div className="blobs">
            <div className="blob-center"/>
            <div className="blob"/>
            <div className="blob"/>
            <div className="blob"/>
            <div className="blob"/>
            <div className="blob"/>
            <div className="blob"/>
        </div>
    )
}


