import React from "react";
import {Row, Col, Card} from "react-bootstrap";
import "./index.scss";
import {HOMEPAGE} from "../../assets/strings/en";
import {Facebook} from 'fb';
import {motion} from "framer-motion";
import {TwitterTimelineEmbed} from "react-twitter-embed"

export default function SocialFeed() {
    const [index, setIndex] = React.useState(0);
    React.useEffect(() => {
        var fb = new Facebook();
        //  fb.extend({appId: '3169688063071555', appSecret: '38b38719e4a0b2d45275205a561188bd'})
        //  fb.setAccessToken('EAAhJ01hiXNkBABWzeZBnewxynILucB6XstPdLGJqLbpqYPxTlkggY1LMClWsx8OM9TIFQMYrqnDTePnZC4YZB0jBsitqOhRKNU18Wycn4yj6LNXPcDN2LkDxwU9eSG1l2eFZC01NUz9PTMGH3vK30NYU4jakGwZARInSZC5jYSbUX0FlgDDO6erHihqDtE0rdcR0rw64WBLAZDZD');
        //   fb.api('/106888053993437/feed', (res) => {
        //       console.log(res);
        //   })
        //   console.log(fb);


    }, [])


    return <>
        <div className="bg-primary-gradient">
            <Row className="align-items-center">
                <Col>{HOMEPAGE.SOCIAL_MEDIA_POST}</Col>
                <Col xs="auto">
                    <ul className="social-icons-div">
                        <motion.li className={index === 0 ? 'active' : ''}>
                            <div onClick={() => setIndex(0)} className="facebook"/>
                        </motion.li>
                        <li className={index === 1 ? 'active' : ''}>
                            <div onClick={() => setIndex(1)} className="twitter"/>
                        </li>
                        {/* <li className={index === 2 ? 'active' : ''}>
                            <div onClick={() => setIndex(2)} className="youtube"/>
                        </li>*/}
                    </ul>
                </Col>
            </Row>
        </div>
        <>

            {index === 0 &&
            <div className="d-flex justify-content-center">

                <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTribalAffairsIn%2F%20&tabs=timeline&width=433&height=465&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    width="100%" height="465" scrolling="no" frameBorder="0"
                    allowTransparency="true" allow="encrypted-media"/>
            </div>}
            {index === 1 && <>
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="tribalaffairsin"
                    options={{height: 480}}
                />
            </>}
        </>
    </>
}
