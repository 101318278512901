import { AnimatePresence, motion } from "framer-motion";
import { Card } from "react-bootstrap";
import React from "react";
import { useScrollPosition } from "../../hooks";

/**
 * {{a: number, b: number}} position
 * @param children
 * @param Object position - position of path containing left and top coordinates
 * @param  {boolean} show
 * @returns {*}
 * @constructor
 */
export function Tooltip({ children, position = {}, show }) {
  const [isScrolling, setScrolling] = React.useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    setScrolling(true);
  });
  React.useEffect(() => {
    setScrolling(false);
  }, [position]);

  return (
    <AnimatePresence exitBeforeEnter>
      {!isScrolling && "left" in position && (
        <motion.div
          id="tooltip"
          style={{ position: "fixed", zIndex: 1, fontSize: ".8rem" }}
          transition={{ type: "tween", duration: 0.6 }}
          exit={{ opacity: 0 }}
          animate={position}
          className="card border-1 border-secondary"
        >
          <Card.Body>{children}</Card.Body>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
