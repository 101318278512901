import React from "react";
import {Row, Col, Card, Image, Button} from "react-bootstrap";
import "./index.scss";
import {HOMEPAGE} from "../../assets/strings/en";
import {useFetch} from "../../hooks";
import {getApiWithLanguageParams} from "../../services";
import Loader from "../loader";
import {timeSince} from "../../utils";
import {AnimatePresence, motion} from "framer-motion";
import {ModalDialog} from "../modal";

const ITEMS_PER_PAGE = 2;

export default function WhatsNew() {
    const [newsLoading, news, newsError] = useFetch(getApiWithLanguageParams('feeds'));
    const [totalNumber, setTotalNumber] = React.useState(0);
    const newsToShow = news.slice(totalNumber * ITEMS_PER_PAGE, (totalNumber + 1) * ITEMS_PER_PAGE);
    const [card, setCard] = React.useState(null);

    return <Card>
        {/*{totalNumber * 5} {(totalNumber + 1) * 5}*/}
        {newsLoading && <Loader/>}
        <Card.Body>
            <ModalDialog
                footer={<Button onClick={() => setCard(null)}>Close</Button>}
                show={!!card}
                onHide={() => setCard(null)}
                header={card && card.name}
                children={<p>{card && card.description}</p>}/>
            <h2>{HOMEPAGE.WHATS_NEW}</h2>
            {newsToShow.map((feed, index) => <WhatsNewCard onReadMore={() => setCard(feed)} key={index} {...feed}/>)}
            {news.length > totalNumber &&
            <div className="d-flex justify-content-between mt-2">
                <Button disabled={totalNumber < 1} variant="outline-primary"
                        onClick={() => setTotalNumber(n => n - 1)}
                >{HOMEPAGE.PREV}</Button>
                <Button disabled={(totalNumber + 1) * ITEMS_PER_PAGE >= news.length} variant="outline-secondary"
                        onClick={() => setTotalNumber(n => n + 1)}>{HOMEPAGE.NEXT}</Button>
            </div>}
        </Card.Body>
    </Card>
}

function WhatsNewCard({onReadMore, ...feed}) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div animate={{opacity: 1}} key={feed.id} initial={{opacity: 0}} exit={{opacity: 0}}
                        className="card-body bg-light mb-4">
                <Row>
                    <Col sm="2">
                        <img
                            src={feed.image ? `${feed.image}` : require("../../assets/images/whats-new.png")}
                            className="img-fluid"
                            alt={feed.title}
                        />
                    </Col>
                    <Col sm="10">
                        <h4 title={feed.name} className="overflow-hidden whitespace-no-wrap"
                            style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{feed.link ?
                            <a href={feed.link}>{feed.name}</a> : feed.name}</h4>
                        <motion.p className={expanded ? 'expanded' : 'collapsed'}>
                            {feed.description ?
                                feed.description.length > 215 ? <>
                                    {expanded ? feed.description : feed.description.slice(0, 230)}
                                    <span role='button' onClick={onReadMore}
                                          className="text-secondary">... {expanded ? 'See less' : 'Read More'}
                                    </span>
                                </> : <>{feed.description}</> : ''}
                        </motion.p>
                        <div
                            className="small text-muted">{HOMEPAGE.WHATS_NEW_DATE} {timeSince(new Date(feed.created_at))}
                        </div>
                    </Col>
                </Row>
            </motion.div>
        </AnimatePresence>
    )

}
