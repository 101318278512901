import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";
import { getApiWithLanguageParams } from "../../services";
import { useFetch } from "../../hooks";
import "./important-docs.scss";
import { Link } from "react-router-dom";
import Loader from "../loader";

const ImportantDocs = () => {
  const [fileState, setFileState] = useState([]);
  const [resourceLoading, resources, resourceError, { reload }] = useFetch(
    getApiWithLanguageParams(
      `resource?name=Aashwasan: 100 Day Campaign on COVID %26 TB`
    )
  );
  const [themeLoading, theme, themeError] = useFetch(
    getApiWithLanguageParams(`theme?name=('TB', 'Covid')`)
  );

  const resourceIds = resources?.map((obj) => obj.id);
  const themeIds = theme?.map((obj) => obj.id);

  const [filesLoading, filesRes, filesErr] = useFetch(
    getApiWithLanguageParams(
      `recentTbCovidFiles?count=3&theme=[${themeIds}]&resource=[${resourceIds}]`
    )
  );

  useEffect(() => {
    if (Object.values(filesRes).length > 0) setFileState(filesRes);
  }, [filesRes]);

  return (
    <Card style={{ minHeight: "446px" }}>
      {(filesLoading || resourceLoading || themeLoading) && <Loader />}
      <Card.Body>
        <h2 className="mb-4">Important Documents</h2>
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex">
            {fileState && fileState.files?.length > 0 ? (
              fileState.files.map((file) => (
                <motion.div
                  key={file.id}
                  onClick={() => window.open(file.document)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    opacity: {
                      delay: 0.2,
                      duration: 0.6,
                    },
                  }}
                  role="button"
                  layoutTransition={{ type: "tween", duration: 0.5 }}
                  className={`mb-4 flex-fill col-6 `}
                >
                  <Card>
                    <Card.Img
                      className=" px-0 "
                      variant="top"
                      alt="image"
                      src={file.image}
                      height="160"
                    />
                    <Card.Text className="p-2">{file.title}</Card.Text>
                  </Card>
                </motion.div>
              ))
            ) : (
              <p>Please upload any file</p>
            )}
          </div>
          {fileState?.total && fileState.total > 3 && (
            <Link to="/knowledge-repository">More</Link>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ImportantDocs;
