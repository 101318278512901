import "./index.scss";
import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import { CSVLink } from "react-csv";
import {
  DashboardCard,
  DashboardCardSmall,
} from "../../components/dashboard-card";
import Loader from "../../components/loader";
import { LeafletMap } from "../../components/map";
import {
  MapDataContext,
  MapDispatchContext,
} from "../../contexts/dashboard-map";
import { useFetch } from "../../hooks";
import {
  SET_DISTRICT,
  SET_SELECTED_STATE,
  SET_STATE_ONLY_VIEW,
} from "../../reducers/constants";
import { getApiWithLanguageParams } from "../../services";
import { isValueNumeric, scrollLeft } from "../../utils";

const DashboardTheme = React.lazy(() =>
  import("../../components/dasboard-theme")
);

function DasboardToolBar({ children }) {
  return children;
}

let count = 0;

export function Dashboard() {
  const { selectedState, geoType, stateOnly, selectedDistrict, districtOnly } =
    React.useContext(MapDataContext);
  const basicApi = React.useRef();
  basicApi.current =
    geoType === "district" ? "dashboard/district/data" : "dashboard/data";
  const dispatch = React.useContext(MapDispatchContext);
  const [stateLoading, states, stateError] = useFetch(
    getApiWithLanguageParams("state?tribal=true")
  );
  const indicatorAPiParams =
    geoType === "district"
      ? `district=${JSON.stringify(selectedDistrict.map((d) => d.id))}&state=${
          selectedState.id
        }`
      : "";
  const [indicatorLoading, indicators, indicatorError] = useFetch(
    getApiWithLanguageParams(
      `${basicApi.current}?geoType=${geoType}&${indicatorAPiParams}`
    )
  );
  const [districtLoading, districts, districtError] = useFetch(
    !!selectedState
      ? getApiWithLanguageParams(
          `district/state/${selectedState.id}?tribal=true`
        )
      : null,
    {
      defaultValue: [],
      when: selectedState && stateOnly && states.length,
    }
  );

  React.useEffect(() => {
    count++;
    if (!districts || !districts.length) return;
    if (!selectedDistrict || !selectedDistrict.length) return;
    dispatch({ type: SET_DISTRICT, payload: [districts[0]] });
  }, [districts, districtLoading]);

  function handleStateChange(event) {
    const selectedState = states.find(
      (state) => +state.id === +event.target.value
    );

    dispatch({ type: SET_SELECTED_STATE, payload: selectedState });
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  let visibleIndicators = [];
  visibleIndicators =
    geoType === "state"
      ? indicators.filter(
          (indicator) => +indicator.state_code === +selectedState.state_code
        )
      : indicators;

  return (
    <Container className="my-4">
      <Card>
        <Card.Body>
          <Row>
            <Col sm={geoType === "district" ? "5" : "6"}>
              <Row>
                <Col xs={12}>
                  {geoType === "district" && (
                    <DasboardToolBar>
                      <Row>
                        <span
                          role="button"
                          className="d-flex m-1"
                          onClick={() =>
                            dispatch({
                              type: SET_STATE_ONLY_VIEW,
                              payload: selectedState,
                            })
                          }
                        >
                          <span className="material-icons">arrow_back</span>
                        </span>

                        <Form.Group className={"col"}>
                          <Form.Control
                            disabled={districtLoading}
                            value={(selectedState && selectedState.id) || ""}
                            onChange={handleStateChange}
                            as="select"
                          >
                            <option disabled={stateOnly} value={null}>
                              All India
                            </option>
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        {districtOnly && (
                          <Form.Group className={"col"}>
                            <MultiSelect
                              hasSelectAll={true}
                              value={selectedDistrict.map((d) => {
                                return {
                                  ...d,
                                  label: d.name,
                                  value: d.id,
                                };
                              })}
                              onChange={(values) => {
                                dispatch({
                                  type: SET_DISTRICT,
                                  payload: values,
                                });
                              }}
                              labelledBy={"Select"}
                              options={districts.map((d) => {
                                return {
                                  ...d,
                                  label: d.name,
                                  value: d.id,
                                };
                              })}
                            />
                          </Form.Group>
                        )}
                      </Row>
                    </DasboardToolBar>
                  )}
                </Col>

                <Col xs={12}>
                  {geoType === "state" && (
                    <span
                      role="button"
                      className="d-flex"
                      onClick={() =>
                        dispatch({
                          type: SET_STATE_ONLY_VIEW,
                          payload: false,
                        })
                      }
                    >
                      <span className="material-icons">arrow_back</span>
                      Back to All States{" "}
                    </span>
                  )}
                  <LeafletMap states={states} districts={districts} />
                </Col>
              </Row>
            </Col>

            <Col>
              {!districtOnly && (
                <DasboardToolBar>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          value={(selectedState && selectedState.id) || ""}
                          onChange={handleStateChange}
                          as="select"
                        >
                          <option disabled={stateOnly} value={null}>
                            All India
                          </option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button
                        disabled={districts.length < 1 || !stateOnly}
                        variant="secondary"
                        onClick={() =>
                          dispatch({
                            type: SET_DISTRICT,
                            payload: [districts[0]],
                          })
                        }
                        type="button"
                        block
                      >
                        District Level Data
                      </Button>
                    </Col>
                  </Row>
                </DasboardToolBar>
              )}

              {geoType !== "state" && !districtOnly && (
                <div className="my-2">
                  Ministry of Tribal Affairs has identified 177 tribal districts
                  as high priority districts. This dashboard is a comprehensive
                  data repository, collating data from different sources related
                  to health and nutrition of tribal population of these 177
                  districts.
                </div>
              )}

              <h3 className="text-center mt-4">
                {selectedDistrict.length === 1 &&
                  selectedDistrict[0].name + ","}{" "}
                {!!selectedState ? selectedState.name : "India"}
              </h3>

              {indicatorLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <>
                  {selectedDistrict.length <= 1
                    ? geoType === "district"
                      ? visibleIndicators.map((indicator, i) => {
                          if (
                            indicator.district_data &&
                            indicator.district_data.length
                          ) {
                            return (
                              <DashboardCard
                                key={i}
                                imgURL={indicator.icon}
                                // type={indicator.district_data[0].value_type}
                                color={
                                  indicator.district_data[0].value > 50
                                    ? "secondary"
                                    : indicator.district_data[0].value < 20
                                    ? "primary"
                                    : "primary"
                                }
                                label={indicator.title}
                                notation={indicator.district_data[0].notation}
                                value={indicator.district_data[0].value}
                              />
                            );
                          }
                          return null;
                        })
                      : visibleIndicators.map((indicator, i) => (
                          <DashboardCard
                            key={i}
                            imgURL={indicator.icon}
                            //type={indicator.value_type}
                            color={
                              indicator.value > 50
                                ? "secondary"
                                : indicator.value < 20
                                ? "primary"
                                : "primary"
                            }
                            label={indicator.title}
                            value={indicator.value}
                          />
                        ))
                    : visibleIndicators && (
                        <Row noGutters>
                          <Col xs="auto" className="mt-4">
                            <img
                              alt="icon"
                              src={require("../../assets/icons/arrow-left.svg")}
                              className="img-fluid cursor-hand"
                              id="left-button"
                              onClick={() =>
                                scrollLeft(
                                  document.getElementById("scroll-div-top"),
                                  -300,
                                  1000
                                )
                              }
                            />
                          </Col>
                          <Col>
                            <div id="scroll-div-top" className="scroll-div">
                              <Row>
                                <Col sm="5">Indicators</Col>
                                {selectedDistrict.map((d, index) => (
                                  <Col
                                    style={{
                                      // border: '1px solid #004B4E',
                                      background: "#008080",
                                      color: "white",
                                    }}
                                    key={index}
                                  >
                                    {d.name}
                                  </Col>
                                ))}
                              </Row>
                              {visibleIndicators.map((indicator, index) => {
                                let districts = selectedDistrict.map(
                                  (district) => {
                                    if (indicator.district_data) {
                                      let actualDistrict =
                                        indicator.district_data.find(
                                          (d) =>
                                            d.district_code === district.code
                                        );
                                      if (actualDistrict) return actualDistrict;
                                    }
                                    return {
                                      ...district,
                                      value: "N/A",
                                    };
                                  }
                                );
                                return (
                                  <Row key={index}>
                                    <Col sm="5">
                                      <DashboardCardSmall
                                        label={indicator.title}
                                      />
                                    </Col>
                                    {districts.map(
                                      (d, index) =>
                                        d && (
                                          <Col key={index}>
                                            {!isValueNumeric(
                                              d.notation || d.value
                                            ) && !d.notation ? (
                                              <span
                                                className="material-icons"
                                                title={d.notation || d.value}
                                              >
                                                info
                                              </span>
                                            ) : (
                                              d.notation || d.value
                                            )}
                                          </Col>
                                        )
                                    )}
                                  </Row>
                                );
                              })}
                            </div>
                          </Col>
                          <Col xs="auto" className="mt-4">
                            <img
                              alt="imagee"
                              src={require("../../assets/icons/arrow-right.svg")}
                              className="img-fluid cursor-hand"
                              id="right-button"
                              onClick={() =>
                                scrollLeft(
                                  document.getElementById("scroll-div-top"),
                                  300,
                                  1000
                                )
                              }
                            />
                          </Col>
                        </Row>
                      )}
                </>
              )}
              {!!(districtOnly && visibleIndicators.length) && (
                <div className="d-flex flex-column">
                  <small>
                    <em>1. Sources: Census, 2011 and SECC, 2011</em>
                  </small>
                  <small>
                    <em>
                      2. Notations: 'NA': Not Available, 'ST': Scheduled Tribe
                    </em>
                  </small>
                  {selectedDistrict &&
                    selectedDistrict[0] &&
                    selectedDistrict[0].code === 1015 && (
                      <small>
                        <i>
                          <strong>
                            {" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            NOTE::{" "}
                          </strong>
                          Figures shown here are the cumulative data of East &
                          West Jaintia Hills
                        </i>
                      </small>
                    )}
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {districtOnly && <DashboardTheme />}
    </Container>
  );
}
