import React from "react";
import "./index.scss"
import {
    Container,
    Row,
    Col,
    Image,
    Nav,
} from "react-bootstrap";
import {NAVBAR, FOOTER} from "../../assets/strings/en" ;
import {NavLink} from "react-router-dom";

export default function Footer() {

    return (
        <footer>
            <Container className="py-4 pb-2">
                <Row>
                    <Col xs="auto" lg="3">
                        <h5>
                            {FOOTER.FOLLOW_US}
                        </h5>
                        <Row>
                            <Col xs="auto">
                                <a href="http://facebook.com/TribalAffairsIn/" target="_blank"
                                   rel="noopener noreferrer">
                                    <img className="img-fluid" src={require("../../assets/icons/facebook.svg")}
                                         alt={NAVBAR.FACEBOOK}/>
                                </a>
                            </Col>
                            <Col xs="auto">
                                <a href="http://twitter.com/tribalaffairsin" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={require("../../assets/icons/twitter.svg")}
                                         alt={NAVBAR.TWITTER}/>
                                </a>
                            </Col>
                            <Col xs="auto">
                                <a href="https://www.instagram.com/tribalaffairsin" target="_blank"
                                   rel="noopener noreferrer">
                                    <img className="img-fluid" style={{width: '16px'}}
                                         src={require("../../assets/icons/instagram-sketched.svg")}
                                         alt={NAVBAR.IG}/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col >
                        <div dangerouslySetInnerHTML={{__html: FOOTER.DISCLAIMER}} className="disclaimer"/>
                    </Col>
                    <Col xs="auto" lg="3">
                        <Row>
                            <Col x="auto">
                                <Nav className="flex-column">
                                    <NavLink className="nav-link" to={'dashboard'}>{NAVBAR.DASHBOARD}</NavLink>
                                    <NavLink className="nav-link disabled"
                                             to={'knowledge-repository'}>{NAVBAR.KR}</NavLink>
                                    <NavLink className="nav-link disabled"
                                             to={'knowledge-repository'}>{NAVBAR.TBP}</NavLink>
                                    <NavLink className="nav-link disabled"
                                             to={'budget-tracker'}>{NAVBAR.BUDGET_TRACKER}</NavLink>
                                </Nav>
                            </Col>
                            <Col x="auto">
                                <Nav className="flex-column">
                                    <NavLink className="nav-link" to={'about'}>{NAVBAR.ABOUT}</NavLink>
                                    <NavLink className="nav-link"
                                             to={'related-websites'}>{NAVBAR.RELATED_WEBSITE}</NavLink>
                                    <NavLink className="nav-link" to={'partnerOrganisation'}>{NAVBAR.PO}</NavLink>
                                    <NavLink className="nav-link" to='/contact-us'>{NAVBAR.CONTACT_US}</NavLink>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* <hr className="bg-light"/> */}
                <Row>
                    <Col className="text-center">
                        <small>{FOOTER.MANAGED_BY}</small>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
