export const indicatorCSVCols = [
  { id: "state_id" },
  { id: "district_id" },
  { id: "state_code" },
  { id: "state_name" },
  { id: "district_name" },
  { id: "no_of_blocks_covered" },
  { id: "no_of_villages_covered" },
  { id: "no_of_districts_covered" },
  { id: "no_of_people_reached" },
  { id: "no_of_people_screened_for_tb" },
  { id: "no_of_people_with_persumptive_tb" },
  { id: "no_of_people_tested_for_tb" },
  { id: "no_of_positive_tb_cases" },
  { id: "no_of_tb_positive_persons_started_on_treatment" },
  { id: "as_on_date" },
];

export const indicatorCSVData = [
  {
    state_id: 10,
    district_id: 93,
    state_code: "32",
    district_name: "Kannur",
    state_name: "Kerala",
    no_of_blocks_covered: 1100,
    no_of_villages_covered: 700,
    no_of_districts_covered: 400,
    no_of_people_reached: 20000,
    no_of_people_screened_for_tb: 15000,
    no_of_people_with_persumptive_tb: 13000,
    no_of_people_tested_for_tb: 11000,
    no_of_positive_tb_cases: 800,
    no_of_tb_positive_persons_started_on_treatment: 200,
    as_on_date: "2022-03-24",
  },
  {
    state_id: 5,
    district_id: 663,
    state_code: '24',
    district_name: "Batod",
    state_name: "Gujarat",
    no_of_blocks_covered: 500,
    no_of_villages_covered: 300,
    no_of_districts_covered: 200,
    no_of_people_reached: 10000,
    no_of_people_screened_for_tb: 7000,
    no_of_people_with_persumptive_tb: 6000,
    no_of_people_tested_for_tb: 3000,
    no_of_positive_tb_cases: 1000,
    no_of_tb_positive_persons_started_on_treatment: 500,
    as_on_date: "2022-01-29",
  },
  {
    state_id: 20,
    district_id: 323,
    state_code: '27',
    district_name: "Aurangabad",
    state_name: "Maharashtra",
    no_of_blocks_covered: 700,
    no_of_villages_covered: 500,
    no_of_districts_covered: 400,
    no_of_people_reached: 10200,
    no_of_people_screened_for_tb: 7200,
    no_of_people_with_persumptive_tb: 6200,
    no_of_people_tested_for_tb: 3200,
    no_of_positive_tb_cases: 1200,
    no_of_tb_positive_persons_started_on_treatment: 700,
    as_on_date: "2022-03-29",
  },
];
