import React from "react";
import LanguageProvider from "./contexts/language.context";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from "./components/home";
import { Dashboard } from "./pages/dashboard";
import Homepage from "./pages/homepage";
import { RestrictedRoute } from "./components/route";
import { MapProvider } from "./contexts/dashboard-map";
import Loader from "./components/loader";
import Aashwasan from "./pages/aashwasan";
import CreateAashwasan from "./pages/aashwasan/create-aashwasan";
const ActiveOrganisation = React.lazy(() =>
  import("./pages/partnerOrganisation/activePartnerOrganisation")
);


const PartnerOrganisation = React.lazy(() =>
  import("./pages/partnerOrganisation")
);
const About = React.lazy(() => import("./pages/about"));
const ContactUs = React.lazy(() => import("./pages/contact-us"));
const RelatedWebsite = React.lazy(() => import("./pages/related-website"));
const KnowledgeRepository = React.lazy(() =>
  import("./pages/knowledge-repository")
);
const Resources = React.lazy(() =>
  import("./pages/knowledge-repository/resources")
);
const Upload = React.lazy(() => import("./pages/partnerOrganisation/upload"));
const PartnerList = React.lazy(() =>
  import("./pages/partnerOrganisation/list")
);
const Login = React.lazy(() => import("./pages/login"));
const Alekha = React.lazy(() => import("./pages/alekha"));
const LoginHistory = React.lazy(() => import("./pages/login-history"));

function App() {
  return (
    <LanguageProvider>
      <React.Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route
                path="dashboard"
                element={
                  <MapProvider>
                    <Dashboard />
                  </MapProvider>
                }
              />
              <Route path="partnerOrganisation" element={<Outlet />}>
                <RestrictedRoute path="/upload" element={<Upload />} />
                <RestrictedRoute path="list" element={<PartnerList />} />
                <RestrictedRoute path="/upload/:id" element={<Upload />} />
                <Route path="/:id" element={<ActiveOrganisation />} />
                <Route path="/" element={<PartnerOrganisation />} />
              </Route>
              <Route path="login-history" element={<Outlet />}>
                <Route path="list" element={<LoginHistory />} />
              </Route>
              <Route path="home" element={<Homepage />} />
              <Route path="tribal" element={<Outlet />}>
                <Route path="/" element={<Aashwasan />} />
                <RestrictedRoute path="create" element={<CreateAashwasan />} />
              </Route>
              <Route path="about" element={<About />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="related-websites" element={<RelatedWebsite />} />
              <Route path="knowledge-repository" element={<Outlet />}>
                <Route path="/" element={<KnowledgeRepository />} />
                <RestrictedRoute path=":id" element={<Resources />} />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="alekha" element={<Alekha />} />
            </Route>
            {/*<Route path="home" element={ <Homepage /> }/>*/}
            {/*<Route path="about" element={ <About /> }/>*/}
            {/*<Route path="contact-us" element={ <ContactUs /> }/>*/}
          </Routes>
        </BrowserRouter>
      </React.Suspense>
    </LanguageProvider>
  );
}

export default App;
