export const NAVBAR = {
    LOGIN: 'लॉग इन करें',
    HOME: 'घर',
    DASHBOARD: 'डैशबोर्ड',
    PO: 'साझेदार',
    KNOWLEDGE_REPOSITORY: "ज्ञान भंडार",
    TRIBAL_HEALTH_PLAN: "आदिवासी स्वास्थ्य योजना",
    BUDGET_TRACKER: "बजट ट्रैकर",
    BT: "बजट ट्रैकर",
    ABOUT: "हमारे बारे में",
    RELATED_WEBSITE: "संबंधित वेबसाइट",
    CONTACT_US: "संपर्क करें",
    FACEBOOK: "फेसबुक",
    TWITTER: "ट्विटर",
    YOUTUBE: "यूट्यूब",
    PLEASE_WAIT: 'Please wait...'
}

export const hi = {
    ...NAVBAR
}
