import React from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import "./index.scss";
import HomeCarousel from "../../components/homeCarousel";
import SocialFeed from "../../components/social-feed";
import WhatsNew from "../../components/whats-new";
import PartnerLogos from "../../components/partner-logos";
import { HOMEPAGE } from "../../assets/strings/en";
import { useNavigate } from "react-router-dom";
import MessageBoard from "../../components/message-board";

export default function Homepage() {
  const navigate = useNavigate();
  return (
    <div>
      <HomeCarousel />
      <section className="page-bg py-4">
        <Container>
          <Card className="mb-4">
            <Card.Body>
              <Row className="align-items-center">
                <Col sm="6">
                  {/* <h2 className="title">{HOMEPAGE.ABOUT_US}</h2> */}
                  <p>{HOMEPAGE.ABOUT_US_TEXT_P1}</p>
                  <p>{HOMEPAGE.ABOUT_US_TEXT_P2}</p>
                  <p>{HOMEPAGE.ABOUT_US_TEXT_P3}</p>
                  {/* <Button onClick={() => navigate('/about')}
                                        variant="primary">{HOMEPAGE.READ_MORE}</Button> */}
                </Col>
                <Col sm="6">
                  <img
                    src={require("../../assets/images/Banda-Village-Odisha.jpg")}
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <Container>
          <Row>
            <Col sm="8" className="mb-3 mb-sm-0">
              <WhatsNew />
            </Col>
            <Col sm="4">
              <SocialFeed />
            </Col>
          </Row>
        </Container>

        <Container>
          <MessageBoard></MessageBoard>
        </Container>
      </section>

      <section className="shadow-top py-4">
        <Container>
          {/* <h2>{HOMEPAGE.OUR_PARTNER}</h2> */}
          <PartnerLogos />
        </Container>
      </section>
    </div>
  );
}
