import "./dashboard-card.scss";

import React from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";

export function DashboardCard({
  imgURL,
  label,
  value,
  notation,
  type = "number",
  color = "secondary",
  progressBar = true,
  onClick,
  chartIcon = false,
}) {
  return (
    <Card className="shadow-sm mb-4">
      <Card.Body>
        <Row className="align-items-center">
          {imgURL && (
            <Col xs="auto">
              <img src={imgURL} className="img-fluid" alt={label} />
            </Col>
          )}
          <Col>
            <Row className="align-items-center">
              <Col>{label}</Col>
              <Col xs="auto" className="text-right">
                <h3 className="m-0">
                  {isNaN(value) && !notation ? (
                    <span title={value} className="material-icons">
                      info
                    </span>
                  ) : (
                    <>
                      {notation || value}
                      {type === "percentage" && "%"}
                    </>
                  )}
                </h3>
              </Col>
            </Row>
            {progressBar && (
              <Row className="mt-3">
                <Col>
                  {type === "percentage" && (
                    <ProgressBar now={value} variant={color} />
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {chartIcon && (
            <Col xs="2" role="button" onClick={onClick}>
              <img
                src={require("../../assets/icons/chart.svg")}
                className="img-fluid"
              />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

export function DashboardCardSmall({ label, chartIcon = false, onClick }) {
  return (
    <Row className="py-2 w-100">
      <Col xs={10}>{label}</Col>
      {chartIcon && (
        <Col xs="2" className="" role="button" onClick={onClick}>
          <img
            src={require("../../assets/icons/chart.svg")}
            className="img-fluid"
            alt="Trend Icon"
          />
        </Col>
      )}
      {/*  <Col xs="auto" className="">
                                        <img src={require("../../assets/icons/chart.svg")} className="img-fluid" />
                                        </Col>*/}
    </Row>
  );
}
