import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const DashboardCardForDistrict = ({ imageUrl, title, description }) => {
  return (
    <Card className="shadow-sm mb-3 mx-2" style={{ flex: "6" }}>
      <Card.Body
        className="p-3 d-flex"
        style={{
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 0px 20px -10px #000",
          borderRadius: "10px",
        }}
      >
        {imageUrl && <img src={imageUrl} alt="icon" />}
        <p
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            margin: "2px 0",
            textAlign: "center"
          }}
        >
          {description}
        </p>
        <p style={{ margin: "0", textAlign: "center" }}>{title}</p>
      </Card.Body>
    </Card>
  );
};

export default DashboardCardForDistrict;
