import "./index.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import Header from "../header";
import Footer from "../footer";
import UserProvider from "../../contexts/user-context";

export default function Home() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (pathname === "/") {
      navigate("home");
    }
  }, [pathname, navigate]);

  return (
    <UserProvider>
      <Header />
      <Outlet />
      <Footer />
    </UserProvider>
  );
}
