import React from "react";
import "./index.scss";
import { Topnav } from "../topnav";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import {
  LanguageDataContext,
  LanguageDispatchContext,
  LanguageTranslationProvider,
} from "../../contexts/language.context";
import { NAVBAR, LOGOUT } from "../../assets/strings/en";
import { languages } from "../../assets/json/languages";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/login";
import { loginRequestOptions, getData } from "../../services/form.service";
import { LOGOUT_USER } from "../../reducers/constants";
import {
  UserDispatchContext,
  UserStateContext,
} from "../../contexts/user-context";
import { ModalDialog } from "../modal";
import { BASE_URL } from "../../services";

export default function Header() {
  const languageDispatch = React.useContext(LanguageDispatchContext);
  const translate = React.useContext(LanguageTranslationProvider);
  const { lng } = React.useContext(LanguageDataContext);
  const navigate = useNavigate();
  const user = React.useContext(UserStateContext);
  const dispatch = React.useContext(UserDispatchContext);
  const isLogin = isAuthenticated();
  const [openModal, setOpenModal] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loginButtonText, setLoginButtonText] = React.useState("Logout");

  function loginFailureHandler(error) {
    setError(translate(LOGOUT.ERROR));
    setLoginButtonText(translate(NAVBAR.LOGOUT));
  }

  function onClick() {
    getData(`${BASE_URL}logout`)
      .then(() => {
        setOpenModal(false);
        navigate("/home");
        dispatch({ type: LOGOUT_USER });
      })
      .catch(loginFailureHandler);
  }

  return (
    <section className="bg-white">
      <header>
        <ModalDialog
          size={"sm"}
          show={openModal}
          onHide={() => setOpenModal(false)}
          children={
            <>
              <div className="d-flex w-100 justify-content-end">
                <Button
                  variant="light"
                  className="mr-2"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </Button>
                <Button onClick={onClick} variant="primary">
                  Yes
                </Button>
              </div>
            </>
          }
          header={<h4>Are you sure you want to logout?</h4>}
        />
        <Container className="py-2">
          <Row className="align-items-center" noGutters>
            <Col>
              <Row className="mb-2">
                <Col xs="auto">
                  <img
                    src={require("../../assets/icons/emblem.svg")}
                    className="img-fluid"
                    width="50"
                  />
                </Col>
                <Col>
                  <div className="h2 m-0 text-secondary font-weight-bold">
                    Swasthya
                  </div>
                  <div className="font-weight-semibold">
                    Tribal Health & Nutrition Portal
                  </div>
                  <div className="small">Ministry of Tribal Affairs</div>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row>
                <Col xs="auto">
                  {/*<Form.Group>*/}
                  {/*    <Form.Control*/}
                  {/*        onChange={(event, a) => languageDispatch({type: event.target.value})}*/}
                  {/*        value={lng || '1'} as="select">*/}
                  {/*        {languages.map((language) => <option key={language.code}*/}
                  {/*                                             value={language.lng}>{language.name}</option>)}*/}
                  {/*    </Form.Control>*/}
                  {/*</Form.Group>*/}
                </Col>
                <Col xs="auto">
                  {!isLogin && (
                    <Button
                      variant="outline-secondary"
                      onClick={() => navigate("/login")}
                    >
                      {translate(NAVBAR.LOGIN)}
                    </Button>
                  )}

                  {isLogin && (
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setOpenModal(true);
                        // dispatch({type: LOGOUT_USER})
                      }}
                    >
                      {translate(NAVBAR.LOGOUT)}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Topnav />
      </header>
    </section>
  );
}
