import React from "react";
import { Button } from "react-bootstrap";
import CsvDownload from "react-csv-downloader";
import { BASE_URL } from "../../../services";
import { indicatorCSVCols, indicatorCSVData } from "./SampleCsv";
import "../index.scss";

const AashwasanBulkUpload = () => {
  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file.name.includes(".csv")) {
      const formData = new FormData();
      formData.append("csv", file);
      fetch(`${BASE_URL}aashwasanIndicatorsUpload`, {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          if (res.ok) {
            alert("Data uploaded successfully!");
          }
          alert("Something went wrong!");
          return res.json();
        })
        .then((json) => {
          console.log("res: ", json);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    } else {
      alert("Please select .csv file only!");
    }
  };

  return (
    <div className="col py-4 d-flex flex-column align-items-center">
      <CsvDownload
        filename="sampleIndicator"
        extension=".csv"
        columns={indicatorCSVCols}
        datas={indicatorCSVData}
      >
        <Button className="btn-secondary">Download sample csv file</Button>
      </CsvDownload>
      <div className="aashwasanBulkUploadInput">
        <p className="fw-bold f-5 ">
          Upload a .csv file by clicking the icon bellow
        </p>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <input type="file" accept=".csv" onChange={handleFileSelect} />
        </div>
      </div>
    </div>
  );
};

export default AashwasanBulkUpload;
