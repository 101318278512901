import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFetch } from "../../../hooks";
import { BASE_URL, getApiWithLanguageParams } from "../../../services";
import { postData } from "../../../services/form.service";
import "../index.scss";

const aashwasanFormValidationSchema = Yup.object().shape({
  state_id: Yup.string().required("Please select a state"),
  district_id: Yup.string().required("Please select a district"),
  no_of_blocks_covered: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_villages_covered: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_districts_covered: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_people_reached: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_people_screened_for_tb: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_people_with_persumptive_tb: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_people_tested_for_tb: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_positive_tb_cases: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  no_of_tb_positive_persons_started_on_treatment: Yup.number()
    .integer()
    .typeError("Only Number is allowed!")
    .required("At least enter 0, don't keep it empty"),
  as_on_date: Yup.date(),
});

const AashwasanForm = () => {
  const [stateId, setStateId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [statesLoading, statesRes, statesError] = useFetch(
    getApiWithLanguageParams("state")
  );

  useEffect(() => {
    const getDistricts = async () => {
      try {
        const headers = new Headers({
          "x-access-token": localStorage.getItem("token"),
        });
        let user = localStorage.getItem("user");
        if (user) {
          user = JSON.parse(user);
          headers.append("role", user.role);
        }
        const dist = await fetch(
          `${BASE_URL}district/state/${stateId}?lng=1`,
          headers
        );
        const dist1 = await dist.json();
        // console.log("dist1: ", dist1?.data);
        setDistricts(dist1.data);
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    if (stateId) {
      getDistricts();
    }
  }, [stateId]);

  return (
    <div className="col d-flex flex-column justify-content-center align-items-center py-4">
      <h4 className="text-center p-4">Create Aashwasan Coverage</h4>
      <Formik
        initialValues={{
          state_id: "",
          district_id: "",
          no_of_blocks_covered: "",
          no_of_villages_covered: "",
          no_of_districts_covered: "",
          no_of_people_reached: "",
          no_of_people_screened_for_tb: "",
          no_of_people_with_persumptive_tb: "",
          no_of_people_tested_for_tb: "",
          no_of_positive_tb_cases: "",
          no_of_tb_positive_persons_started_on_treatment: "",
          as_on_date: "",
        }}
        validationSchema={aashwasanFormValidationSchema}
        onSubmit={async (values, actions) => {
          values.state_name = values.state_id.split(",")[2];
          values.state_code = values.state_id.split(",")[1];
          values.state_id = values.state_id.split(",")[0];
          values.district_name = values.district_id.split(",")[1];
          values.district_id = values.district_id.split(",")[0];
          console.log("Submit: ", values);
          try {
            const res = await postData(`${BASE_URL}district-details`, values);
            alert("Created successfully");
            actions.resetForm();
            console.log("Submit: ", await res);
          } catch (error) {
            console.log("Error: ", error);
          }
        }}
      >
        {function Render({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          values,
          errors,
          touched,
        }) {
          // useEffect(() => {
          //   // console.log("errors: ", errors);
          //   // console.log("touched: ", touched);
          //   console.log("values: ", values);
          // }, [ values]);
          return (
            <Form className="form" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="searchState">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  name="state_id"
                  value={values.state_id}
                  onChange={(e) => {
                    handleChange(e);
                    setStateId(e.target.value.split(",")[0]);
                  }}
                  onBlur={(e) => handleBlur(e)}
                >
                  <option value="">Select a state</option>
                  {statesRes?.length &&
                    statesRes.map((state) => (
                      <option
                        key={state.id}
                        value={`${state.id},${state.state_code},${state.name}`}
                      >
                        {state.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.state_id && touched.state_id && (
                  <Form.Text className="text-danger">
                    {errors.state_id}
                  </Form.Text>
                )}
              </Form.Group>
              {values.state_id && (
                <Form.Group className="mb-3" controlId="searchState">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    as="select"
                    name="district_id"
                    value={values.district_id}
                    onChange={handleChange}
                    onBlur={(e) => handleBlur(e)}
                  >
                    <option value="">Select a district</option>
                    {districts?.length &&
                      districts.map((district) => (
                        <option
                          key={district.id}
                          value={`${district.id},${district.name}`}
                        >
                          {district.name}
                        </option>
                      ))}
                  </Form.Control>
                  {errors.district_id && touched.district_id && (
                    <Form.Text className="text-danger">
                      {errors.district_id}
                    </Form.Text>
                  )}
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Number of Blocks Covered</Form.Label>
                <Form.Control
                  name="no_of_blocks_covered"
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e)}
                  value={values.no_of_blocks_covered}
                  type="text"
                />
                {errors.no_of_blocks_covered &&
                  touched.no_of_blocks_covered && (
                    <Form.Text className="text-danger">
                      {errors.no_of_blocks_covered}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of Villages Covered</Form.Label>
                <Form.Control
                  name="no_of_villages_covered"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_villages_covered}
                  type="text"
                />
                {errors.no_of_villages_covered &&
                  touched.no_of_villages_covered && (
                    <Form.Text className="text-danger">
                      {errors.no_of_villages_covered}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of Districts Covered</Form.Label>
                <Form.Control
                  name="no_of_districts_covered"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_districts_covered}
                  type="text"
                />
                {errors.no_of_districts_covered &&
                  touched.no_of_districts_covered && (
                    <Form.Text className="text-danger">
                      {errors.no_of_districts_covered}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of People reached</Form.Label>
                <Form.Control
                  name="no_of_people_reached"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_people_reached}
                  type="text"
                />
                {errors.no_of_people_reached &&
                  touched.no_of_people_reached && (
                    <Form.Text className="text-danger">
                      {errors.no_of_people_reached}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of People screened for TB</Form.Label>
                <Form.Control
                  name="no_of_people_screened_for_tb"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_people_screened_for_tb}
                  type="text"
                />
                {errors.no_of_people_screened_for_tb &&
                  touched.no_of_people_screened_for_tb && (
                    <Form.Text className="text-danger">
                      {errors.no_of_people_screened_for_tb}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of People with persumptive TB</Form.Label>
                <Form.Control
                  name="no_of_people_with_persumptive_tb"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_people_with_persumptive_tb}
                  type="text"
                />
                {errors.no_of_people_with_persumptive_tb &&
                  touched.no_of_people_with_persumptive_tb && (
                    <Form.Text className="text-danger">
                      {errors.no_of_people_with_persumptive_tb}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of People tested for TB</Form.Label>
                <Form.Control
                  name="no_of_people_tested_for_tb"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_people_tested_for_tb}
                  type="text"
                />
                {errors.no_of_people_tested_for_tb &&
                  touched.no_of_people_tested_for_tb && (
                    <Form.Text className="text-danger">
                      {errors.no_of_people_tested_for_tb}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Number of Positive TB cases</Form.Label>
                <Form.Control
                  name="no_of_positive_tb_cases"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_positive_tb_cases}
                  type="text"
                />
                {errors.no_of_positive_tb_cases &&
                  touched.no_of_positive_tb_cases && (
                    <Form.Text className="text-danger">
                      {errors.no_of_positive_tb_cases}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Number of TB Positive Persons started on treatment
                </Form.Label>
                <Form.Control
                  name="no_of_tb_positive_persons_started_on_treatment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.no_of_tb_positive_persons_started_on_treatment}
                  type="text"
                />
                {errors.no_of_tb_positive_persons_started_on_treatment &&
                  touched.no_of_tb_positive_persons_started_on_treatment && (
                    <Form.Text className="text-danger">
                      {errors.no_of_tb_positive_persons_started_on_treatment}
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>As on date</Form.Label>
                <Form.Control
                  name="as_on_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.as_on_date}
                  type="date"
                />
                {errors.as_on_date && touched.as_on_date && (
                  <Form.Text className="text-danger">
                    {errors.as_on_date}
                  </Form.Text>
                )}
              </Form.Group>
              <Button
                type="submit"
                className="btn-secondary"
                disabled={isSubmitting}
              >
                Create
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AashwasanForm;
