import React from "react";
import { Card, Image, Button, Carousel, Modal } from "react-bootstrap";
import "./index.scss";
import { HOMEPAGE } from "../../assets/strings/en";
import { useFetch } from "../../hooks";
import { BASE_URL, getApiWithLanguageParams } from "../../services";
import Loader from "../loader";
import { s3UploadFile } from "../../utils/helpers";
import { UserStateContext } from "../../contexts/user-context";
import { uploadMediaToS3 } from "../../utils";
import { getData, postData } from "../../services/form.service";
import { useEffect } from "react";
import { useState } from "react";

const ITEMS_PER_PAGE = 2;

export default function Glimpses() {
  const user = React.useContext(UserStateContext);
  const isLoggedIn = "token" in user;
  const [imagesLoading, imagesRes, imagesErr] = useFetch(
    getApiWithLanguageParams("glimpsImage?count=10")
  );
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    content: "",
    imgUrl: "",
  });

  const handleImageUpload = async (event) => {
    event.persist();
    const file = event.target.files[0];
    let extention = ["png", "jpg", "JPEG", "jpeg", "svg"];
    if (file !== undefined) {
      let ext = file.type.split("/");
      if (ext.length && extention.includes(ext[1])) {
        try {
          const result = await uploadMediaToS3(file);
          setFormData({ ...formData, imgUrl: result });
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Please add png, jpg, JPEG, jpeg or svg only");
      }
    }
  };

  const handleSubmit = async () => {
    if (!formData.content || !formData.imgUrl) {
      alert("All fields must be filled");
    } else {
      const response = await postData(`${BASE_URL}uploadGlimpsImage`, formData);
      console.log(response);
      if (response.success) {
        const imagesRes1 = await getData(`${BASE_URL}glimpsImage?count=10`);
        // console.log(imagesRes1.data);
        if (imagesRes1?.success) setData(imagesRes1.data);
      }
      setModalVisible(false);
    }
  };

  useEffect(() => {
    if (imagesRes?.length > 0) setData(imagesRes);
  }, [imagesRes]);

  return (
    <Card style={{ minHeight: "446px" }}>
      {/* {imagesLoading && <Loader />} */}
      <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Contnet File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputWrapper">
            <label htmlFor="">Content</label>
            <textarea
              onChange={(e) =>
                setFormData({ ...formData, content: e.target.value })
              }
              cols="30"
              rows="5"
            ></textarea>
          </div>
          <div className="inputWrapper">
            <label htmlFor="">Image</label>
            <input type="file" onChange={handleImageUpload} />
          </div>
          <Button onClick={handleSubmit}>Submit</Button>
        </Modal.Body>
      </Modal>
      <Card.Body>
        <h2 className="mb-4">{HOMEPAGE.GLIMPSES_FROM_FIELD}</h2>
        <Carousel>
          {data.length > 0 ? (
            data.map((image) => (
              <Carousel.Item
                key={image.id}
                style={{
                  height: `${isLoggedIn ? "280px" : "320px"}`,
                  minHeight: `${isLoggedIn ? "280px" : "320px"}`,
                  backgroundImage: "url(" + image.imgUrl + ")",
                  backgroundSize:
                    window.innerWidth < 1700 ? "cover" : "contain",
                }}
              >
                <div className="carouselContent">
                  <p>{image.content}</p>
                </div>
              </Carousel.Item>
            ))
          ) : (
            <>
              <p>No image found</p>
              <p>Please upload some image</p>
            </>
          )}
        </Carousel>
        {isLoggedIn && (
          <Button className="mt-2" onClick={() => setModalVisible(true)}>
            Upload
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export const UploadButton = ({ handleChange }) => {
  return (
    <Button className="mt-2 imageUploadBtn">
      Upload
      <input
        onChange={handleChange}
        type="file"
        className="imageUploadInputField"
      />
    </Button>
  );
};
