import React from "react";
import * as L from "leaflet";
import boundaries from "../../assets/json/new_districts.json";
import "./index.scss";

const layerStyle = {
  color: "#808080",
  fill: true,
  fillColor: "#fff",
  weight: 0.3,
  opacity: 1,
  fillOpacity: 1,
};

const DistrictMap = ({ districts }) => {
  const mapContainer = React.useRef();
  const currentLayer = React.useRef();

  React.useEffect(() => {
    if (document.getElementById("mapid1")) {
      if (!mapContainer.current) {
        mapContainer.current = L.map("mapid1", {
          preferCanvas: false,
          zoomControl: false,
          doubleClickZoom: false,
          touchZoom: false,
          scrollWheelZoom: false,
          zoom: false,
          dragging: false,
          minZoom: 4,
          zoomSnap: 0.25,
          zoomDelta: 0.25,
        });

      } else {
        mapContainer.current.removeLayer(currentLayer.current);
        if (mapContainer.current) {
          setTimeout(() => {
            mapContainer.current.invalidateSize();
          }, 300);
        }
      }

      currentLayer.current = L.geoJSON(boundaries, {
        style: (feature) => {
          const district = districts.find(
            (dist) => dist.name === feature.properties.DISTRICT
          );
          return {
            ...layerStyle,
            fillColor: district !== undefined ? "#ffb400" : "#ffffff",
          };
        },
        onEachFeature: (feature, layer) => {
          const district = districts.find(
            (dist) => dist.name === feature.properties.DISTRICT
          );
          if (district) {
            layer.bindTooltip(feature.properties.DISTRICT);
          }
        },
      }).addTo(mapContainer.current);
      mapContainer.current.fitBounds(currentLayer.current.getBounds());
      if (mapContainer.current) {
        setTimeout(() => {
          mapContainer.current.invalidateSize();
        }, 300);
      }
    }
  }, []);

  return <div key={"leaflet-map"} id="mapid1" />;
};

export default DistrictMap;
